import { Component, DestroyRef, ElementRef, inject, OnInit, output, SecurityContext, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ChatbotMessageVariantsType } from '@app-types/chatbot/chatbot-message-variants.type';
import { ChatbotMessageType } from '@app-types/chatbot/chatbot-message.type';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { GaardskartAiService } from '@services/gaardskart-ai.service';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { MarkdownModule } from 'ngx-markdown';
import { EMPTY, exhaustMap, retry, Subject } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';

const defaultMessage: ChatbotMessageType = {
  message: 'Hei! Jeg er samtaleroboten Ask. Hva kan jeg hjelpe deg med? Skriv kort og enkelt for at jeg skal forstå.',
  variant: 'incoming',
};

@Component({
  imports: [MarkdownModule, PopoverModule, ReactiveFormsModule, SpinnerComponent],
  selector: 'gk-chatbot',
  styleUrl: './chatbot.component.scss',
  templateUrl: './chatbot.component.html',
})
export class ChatbotComponent implements OnInit {
  #destroyRef = inject(DestroyRef);
  #domSanitizer = inject(DomSanitizer);
  #gaardskartAiService = inject(GaardskartAiService);
  #sendPrompt = new Subject<string>();
  conversation: ChatbotMessageType[] = [defaultMessage];
  conversationPanelRef = viewChild<ElementRef<HTMLDivElement>>('conversationPanelRef');
  inputRef = viewChild<ElementRef<HTMLInputElement>>('inputRef');
  loading = false;
  previousPrompt = '';
  showChatbotPopover = true;
  toggleChatbot = output<void>();

  #clearInput(): void {
    this.inputRef().nativeElement.value = '';
  }

  #focusInput(): void {
    this.inputRef().nativeElement.focus();
  }

  /**
   * Scrolls the chat window so that the latest message of the passed variant is displayed at top
   */
  #scrollToLatestMessage(variant: ChatbotMessageVariantsType): void {
    setTimeout(() => {
      const messages = document.querySelectorAll(`ul.chatbot-messages > li.${variant}`);

      if (messages?.length) {
        const offsetTop = ([...messages].pop() as HTMLElement)?.offsetTop;
        const scrollPos = offsetTop > 50 ? offsetTop - 50 : this.conversationPanelRef().nativeElement.scrollHeight; // Chatbot panel-header approx 50px
        this.conversationPanelRef().nativeElement.scrollTo({ behavior: 'smooth', top: scrollPos });
      }
    }, 10);
  }

  #setupSubscriptions(): void {
    /**
     * This rxjs chain does:
     * - filter: If there is no prompt text, abort
     * - tap: Start spinner, save existing prompt as previous, add as outgoing message and scroll to it
     * - concatMap: Post the new prompt to the backend via the gkAiService.
     * - retry: Next attempt should always fire even if previous errored out
     */
    this.#sendPrompt
      .pipe(
        filter(() => this.inputRef().nativeElement?.value?.length > 0),
        tap(text => {
          this.loading = true;
          this.previousPrompt = this.inputRef().nativeElement.value;
          this.addChatMessage(text, 'outgoing');
          this.#scrollToLatestMessage('outgoing');
        }),
        exhaustMap(text =>
          this.#gaardskartAiService.postPrompt(text).pipe(
            catchError(() => {
              this.addChatMessage('En feil oppstod, vennligst prøv igjen', 'system');
              this.restorePreviousPrompt();
              this.loading = false;
              return EMPTY;
            }),
          ),
        ),
        retry(2),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe({
        error: err => {
          console.log(`err`, err);
          this.restorePreviousPrompt();
          this.loading = false;
        },
        next: res => {
          this.loading = false;
          this.addChatMessage(res.response, 'incoming');
          this.#scrollToLatestMessage('outgoing');
        },
      });
  }

  /**
   * Adds a new message to the conversation
   */
  addChatMessage(msg: string, variant: ChatbotMessageVariantsType = 'outgoing'): void {
    this.conversation.push({
      message: this.#domSanitizer.sanitize(SecurityContext.HTML, msg),
      variant,
    });
    if (variant === 'outgoing') {
      this.#clearInput();
    }
    this.#focusInput();
  }

  focusMsg($event: FocusEvent): void {
    ($event.target as HTMLElement).scrollIntoView();
  }

  /**
   * Handle if user presses Enter key in input field
   */
  handleEnterKey(event: Event): void {
    event.preventDefault();
    if (this.loading) {
      return;
    }
    this.#sendPrompt.next(this.inputRef().nativeElement?.value);
  }

  ngOnInit(): void {
    this.#focusInput();
    this.#setupSubscriptions();
  }

  purgeConversation(): void {
    this.conversation = [defaultMessage];
    this.#gaardskartAiService.clearThreadId();
    this.#focusInput();
  }

  /**
   * If user presses arrowUp, give them their last entered text back
   */
  restorePreviousPrompt(): void {
    if (this.inputRef().nativeElement?.value?.length < 1 && this.previousPrompt?.length > 0) {
      this.inputRef().nativeElement.value = this.previousPrompt;
      this.#focusInput();
    }
  }

  /**
   * When form is submitted
   */
  send(event: SubmitEvent): void {
    event.preventDefault();

    if (this.loading) {
      return;
    }

    this.#sendPrompt.next(this.inputRef().nativeElement?.value);
  }
}
