import { MapConfig } from '@config/map.config';
import { Extent } from 'ol/extent';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import { Geometry } from 'ol/geom';
import Point from 'ol/geom/Point';
import Base from 'ol/layer/Base';
import VectorLayer from 'ol/layer/Vector';
import layerVector from 'ol/layer/Vector';
import { transform } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import Vector from 'ol/source/Vector';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import {
  driftsenterStyle,
  propertyStyleAll,
  propertyStyleAll_notFarm,
  propertyStyleDefault,
  propertyStyleDefault_notFarm,
  textStyleHideUndecided,
} from 'src/app/shared/tools/mapStyles';

export class MapTools {
  /**
   * @param {number} resolution Resolution.
   * @return {number} same scale level as the map
   */
  static getScaleFromResolution = function (resolution) {
    const DOTS_PER_INCH = 130;
    let scale = 29.073 * DOTS_PER_INCH * resolution;
    scale = Math.round(scale);
    return scale;
  };

  /**
   * Will generate apropriate gards- og bruks nr -lable, depending on feature and resolution
   * @param {Feature} feature to style. Must have an area_m2-property
   * @param {number}  resolution
   * @return {string}    The gards- og bruks nr of the feature as a string
   */
  static getTextGBnr = function (feature: Feature, resolution: number): string {
    const text = '';
    const gBnr = feature.get('gnr') + ' / ' + feature.get('bnr') + ' / ' + feature.get('fnr');
    const areal = feature.get('area_m2');
    // no lable if resolution is more than 10 or tiny area
    if (resolution > 40) {
      return text;
    }
    if (
      (areal > 10000000 && resolution < 40) ||
      (areal > 150000 && areal <= 10000000 && resolution < 6) ||
      (areal > 110000 && areal <= 150000 && resolution < 5) ||
      (areal > 20000 && areal <= 110000 && resolution < 3) ||
      (areal >= 1 && areal <= 20000 && resolution < 1)
    ) {
      return gBnr;
    }
    return text;
  };

  /**
   * Create a border layer (polygon area)
   * @param propertyFeatures    property features
   * @param isFarm     landbrukseiendom or grunneiendom
   * @param showAll    if "Vis alle" is checked or not
   * @param initProp   is this the initial property (main eearch)  or not
   * @return {layerVector} layer created based on the given input
   */
  static createBorderLayer(
    propertyFeatures: any,
    isFarm: boolean,
    showAll: boolean,
    initProp: boolean,
  ): VectorLayer<VectorSource> {
    let borderLayer = null;
    if (!initProp) {
      propertyFeatures.forEach(feature => {
        feature.set('added', 'true');
      });
    }

    const propertySource = new Vector({
      features: propertyFeatures,
    });

    let conditionalStyle = null;
    if (!showAll) {
      conditionalStyle = isFarm ? propertyStyleDefault : propertyStyleDefault_notFarm;
    } else {
      conditionalStyle = isFarm ? propertyStyleAll : propertyStyleAll_notFarm;
    }
    borderLayer = new layerVector({
      source: propertySource,
      style: conditionalStyle,
      // setter denne slik at eiendomsgrensene rendres smooth, og ikke mosaik-aktig
      // Lagt til for å fikse GAR-1332
      updateWhileAnimating: true,
    });

    borderLayer.set('id', MapConfig.EIENDOMSGRENSE, true);
    borderLayer.set('legend', 'no', true);
    borderLayer.set('name', 'Eiendomsgrenser', true);
    // borderLayer.setZIndex(4);
    if (initProp) {
      borderLayer.setZIndex(5);
    } else {
      borderLayer.setZIndex(4);
    }

    return borderLayer;
  }

  /**
   * @param  {string} id to set on the point-layer
   * @param  {string} jsonString features containg driftsenterpoint. We just uses the first one.
   * @param dsProj
   * @param mapProj
   * @returns {VectorLayer<VectorSource>}
   */
  static createDriftsenterLayer(id: string, jsonString: string, dsProj, mapProj): VectorLayer<VectorSource> {
    const format = new GeoJSON();
    const features = format.readFeatures(jsonString);
    const driftssenter_north = features[0].get('driftsenterpoint_y');
    const driftssenter_east = features[0].get('driftsenterpoint_x');
    if (driftssenter_north && driftssenter_east) {
      const dsLayer = this.createPointLayer(
        id,
        [driftssenter_east, driftssenter_north],
        dsProj,
        mapProj,
        driftsenterStyle,
      );
      if (dsLayer !== null) {
        dsLayer.set('name', 'Driftssenter');
        dsLayer.set('id', id);
        dsLayer.set('legend', true);
        dsLayer.set('header', true);
        dsLayer.setZIndex(8);
      }
      return dsLayer;
    } else {
      return null;
    }
  }

  static createEmptyLayer(id: string): VectorLayer<VectorSource> {
    const gardskartLayer = new VectorLayer();
    gardskartLayer.set('id', id);
    return gardskartLayer;
  }

  /**
   * @param  {any}   propertyFeatures the features to  use in the GBnrLayer
   * @param showAll
   * @return {layerVector} A layer with hardcoded style on The gards- og bruks nr of the feature
   */
  static createGBnrLayer(propertyFeatures: any, showAll: boolean): VectorLayer<VectorSource> {
    const propertySource = new Vector({
      features: propertyFeatures,
    });
    const gBnrLayer = new layerVector({
      source: propertySource,
    });

    gBnrLayer.setStyle((feature: Feature<Geometry>, resolution) =>
      textStyleHideUndecided(feature, resolution, showAll),
    );
    gBnrLayer.set('id', 'GARDSBRUKSNUMMER', true);
    gBnrLayer.set('legend', 'no', true);
    gBnrLayer.set('name', 'Gårds- og bruksnr', true);
    gBnrLayer.setZIndex(8);
    return gBnrLayer;
  }

  /**
   * Create a point layer (circle)
   * @param  {string}          id       id of new layer
   * @param  {Array<Number>}   coord    coordinates of the layer (feature)
   * @param dsProj
   * @param mapProj
   * @param  {Style}  pstyle   optional style of the point (layer)
   *
   * @return {layerVector}          layer created based on the given input
   */
  private static createPointLayer(
    id: string,
    coord: [number, number],
    dsProj,
    mapProj,
    pstyle?: Style,
  ): VectorLayer<VectorSource> {
    if (coord.length !== 2) {
      return null;
    }
    const defaultStyle = MapTools.getCircleStyle(MapConfig.PROPERTY_COLOR, MapConfig.BLACK, 2, 6);
    const imgStyle = typeof pstyle !== 'undefined' ? pstyle : defaultStyle;
    const feature = new Feature({
      geometry: new Point(transform(coord, dsProj, mapProj)),
      name: id,
    });
    const source = new Vector({
      features: [feature],
    });
    return new layerVector({
      source: source,
      style: [imgStyle],
    });
  }

  /**
   * Generate a gbnr without leading zero for  municipalities smaller than 1000
   * If fnr is 0 the only gnr and bnr is computed
   * @param  municipalityNr kommunenummer
   * @param  gnr             gårdsnummer
   * @param  bnr             bruksnummer
   * @param  fnr             festenummer
   * @return   string containing minimal style gbnr
   */
  static gbnrMinimalStyle(municipalityNr: string, gnr: string, bnr: string, fnr: string): string {
    let muniNr = municipalityNr;
    if (municipalityNr.length === 4 && municipalityNr[0] === '0') {
      muniNr = muniNr.substr(1);
    }
    let gid = muniNr + '-' + gnr + '/' + bnr;
    if (fnr !== null && fnr !== '0') {
      gid += '/' + fnr;
    }

    return gid;
  }
  /**
   * Generate style for pointLayer (circleLayer)
   * @param  {string}         fillColor     fill/area color
   * @param  {string}         strokeColor   stroke/border color
   * @param  {number}         strokeWidth   stroke/border width
   * @param  {number}         circleRadius  radius of circle
   * @return {Style}               generated style for pointLayer
   */
  static getCircleStyle(fillColor: string, strokeColor: string, strokeWidth: number, circleRadius: number): Style {
    return new Style({
      image: new Circle({
        fill: new Fill({
          color: fillColor,
        }),
        radius: circleRadius,
        stroke: new Stroke({
          color: strokeColor,
          width: strokeWidth,
        }),
      }),
    });
  }

  /**
   * compute the minimalstyle gbnr for the feature passed.
   * @param  feature A feture with the 'municipality_nr', 'gnr'
   *                 'bnr' and 'fnr' keys availabel
   * @return         string containing minimal style gBnr
   */
  static getFeatureGbnr(feature): string {
    return MapTools.gbnrMinimalStyle(
      String(feature.get('municipality_nr')),
      String(feature.get('gnr')),
      String(feature.get('bnr')),
      String(feature.get('fnr')),
    );
  }

  /**
   * Compute the extent of the features in the layer with
   * specified ownership
   * @param  layer      The vector layer to search in
   * @param  ownership  Ownership to search for
   * @return   extent of the fetures found to match, null otherwise
   */
  static getOwnershipExtent(layer: VectorLayer<VectorSource>, ownership: string) {
    let ownerExtent: Extent = null;
    const features = layer.getSource().getFeatures();
    features.forEach(feature => {
      const fOwnership = feature.get('ownership');
      if (fOwnership === ownership) {
        if (!ownerExtent) {
          ownerExtent = feature.getGeometry().getExtent();
        } else {
          const extent = feature.getGeometry().getExtent();
          const x_min = Math.min(ownerExtent[0], extent[0]);
          const x_max = Math.max(ownerExtent[2], extent[2]);
          const y_min = Math.min(ownerExtent[1], extent[1]);
          const y_max = Math.max(ownerExtent[3], extent[3]);
          ownerExtent = [x_min, y_min, x_max, y_max];
        }
      }
    });
    return ownerExtent;
  }

  /**
   * Compute the extent of the features in the property with
   * specified gid and the ownertypes passed
   * @param  layer      The vector layer to search in
   * @param  gid        The gid to search for
   * @param  ownerTypes Array of strings
   * @return   extent of the fetures found to match, null otherwise
   */
  static getPropertyExtent(layer: VectorLayer<VectorSource>, gid: string, ownerTypes) {
    let propertyExtent: Extent = null;
    const features = layer.getSource().getFeatures();
    features.forEach(feature => {
      const feature_gid = MapTools.getFeatureGbnr(feature);
      const ownership = feature.get('ownership');
      if (feature_gid === gid && ownership && ownerTypes.indexOf(ownership) >= 0) {
        if (!propertyExtent) {
          propertyExtent = feature.getGeometry().getExtent();
        } else {
          const extent = feature.getGeometry().getExtent();
          const x_min = Math.min(propertyExtent[0], extent[0]);
          const x_max = Math.max(propertyExtent[2], extent[2]);
          const y_min = Math.min(propertyExtent[1], extent[1]);
          const y_max = Math.max(propertyExtent[3], extent[3]);
          propertyExtent = [x_min, y_min, x_max, y_max];
        }
      }
    });
    return propertyExtent;
  }

  /**
   * Generate style for borderLayer
   * @param  {string}         fillColor     fill/area color
   * @param  {string}         strokeColor   stroke/border color
   * @param  {number}         strokeWidth   stroke/border width
   * @return {Style}               generated style for borderLayer
   */
  static getStyle(fillColor: string, strokeColor: string, strokeWidth: number): Style {
    return new Style({
      fill: new Fill({
        color: fillColor,
      }),
      stroke: new Stroke({
        color: strokeColor,
        width: strokeWidth,
      }),
    });
  }

  /**
   * Return extent of the feature in layer that matches gid and teig_nr
   * @param  layer   target layer
   * @param  gid     gid for target teig
   * @param  teigNr id of the teig
   * @return        Extent
   */
  static getTeigExtent(layer: VectorLayer<VectorSource>, gid: string, teigNr: string) {
    const features = layer.getSource().getFeatures();
    let ext = null;
    for (const item of features) {
      const feature_gid = MapTools.getFeatureGbnr(item);
      // Turn higlight off if this is the correct id
      const feature_teig_nr = item.get('teig_nr');
      // console.log("%s[%s] sammenlikner feature_gid(%s) feature_teig_nr(%s)", gid,teig_nr, feature_gid, feature.get('teig_nr'), );
      if (feature_gid === gid && teigNr && teigNr === feature_teig_nr) {
        // console.log("%s[%s]Skrur %s for %s", gid, feature.get('teig_nr'), onOrOff, ownership);
        ext = item.getGeometry().getExtent();
        break;
      }
    }
    return ext;
  }

  /**
   * Checks if layer sholud have legend. Id of layer will be checked
   * compared to known layers.
   * @param  {Base} layerIn The layer to check
   * @return {boolean}       True if layer had id
   */
  static hasLegend(layerIn: Base) {
    return layerIn.get('id') !== MapConfig.GARDSBRUKSNUMMER && !this.isGardskartLayer(layerIn);
  }

  /**
   * Change  the indicate property on all features in the passed layer
   * @param  layer      target layer
   * @param  gid        only features matching this gid
   * @param  onOrOff    Set to ON or OFF
   * @param  ownerTypes apply only to features with ownership in this array
   * @return            void
   */
  static indicateArea(layer, gid, onOrOff, ownerTypes) {
    const features = layer.getSource().getFeatures();
    features.forEach(feature => {
      const feature_gid = MapTools.getFeatureGbnr(feature);
      const ownership = feature.get('ownership');
      if (feature_gid === gid && ownership && ownerTypes.indexOf(ownership) >= 0) {
        // console.log("%s[%s]Skrur %s for %s", gid, feature.get('teig_nr'), onOrOff, ownership);
        feature.set('indicate', onOrOff);
      }
    });
  }

  /**
   * Change  the indicate property on all features in the passed
   * layer that has the specified ownership-type
   * @param  layer      target layer
   * @param  onOrOff    'ON' or 'OFF'
   * @param ownerType apply only to features with this ownership
   * @return            void
   */
  static indicateAreaForOwnerType(layer: VectorLayer<VectorSource>, onOrOff: string, ownerType: string) {
    const features = layer.getSource().getFeatures();
    features.forEach(feature => {
      const ownership = feature.get('ownership');
      if (ownership && ownership === ownerType) {
        // console.log("%s[%s]Skrur %s for %s", gid, feature.get('teig_nr'), onOrOff, ownership);
        feature.set('indicate', onOrOff);
      }
    });
  }

  /**
   * change the value of the indicate property for the features in passed layer
   * that matches gid and teig_nr
   * @param  layer   target layer
   * @param  gid     gid for target property
   * @param  teigNr id of the teig
   * @param  onOrOff 'ON' or 'OFF'
   * @return        void
   */
  static indicateTeig(layer: VectorLayer<VectorSource>, gid: string, teigNr: string, onOrOff: string) {
    const features = layer.getSource().getFeatures();
    features.forEach(feature => {
      const feature_gid = MapTools.getFeatureGbnr(feature);
      // Turn higlight off if this is the correct id
      const feature_teig_nr = feature.get('teig_nr');
      if (feature_gid === gid && teigNr && teigNr === feature_teig_nr) {
        // console.log("%s[%s]Skrur %s for %s", gid, feature.get('teig_nr'), onOrOff, ownership);
        feature.set('indicate', onOrOff);
      }
    });
  }

  /**
   * Checks if layer is a gardskartLayer. To be a gardskartLayer
   * @param  {string} id identity-string to check
   * @return {boolean}       true if the passed string matches the criteria for a gardskart
   *                         (ie Should start with GARDSKART_PREFIX)
   *                         false otherwise
   */
  static isGardskartId(id: string) {
    return id.indexOf(MapConfig.GARDSKART_PREFIX) === 0;
  }

  /**
   * Checks if layer is a gardskartLayer. To be a gardskartLayer theme
   * id shoud pass the isGardskartId-test
   * @param  {layer: Base} layerIn the layer to check
   * @return {[type]}        true if the passed layer matches the criteria for a gardskart
   *                         layer, false otherwise
   */
  static isGardskartLayer(layerIn: Base) {
    return this.isGardskartId(layerIn.get('id'));
  }
}
