export class JsonHelper {
  /**
   * Some of the GK Drawings we are serializing using JSON.stringify()/parse() have Zone-related properties which
   * cause circular structures. This replacer-fn for stringify strips those properties to help avoid errors.
   *
   * @example JSON.stringify(myObj, JsonHelper.stripZones);
   */
  static stripZones(key, val) {
    const keyName: string = key.toLowerCase();
    if (keyName.startsWith('_zone') || keyName.startsWith('__zone')) {
      // console.log(`skipping key ${key}`);
      return;
    }

    const ctorName: string = val?.constructor?.name.toLowerCase();
    if (ctorName?.length && (ctorName.startsWith('_zone') || ctorName.startsWith('__zone'))) {
      // console.log(`skipping obj with ctor ${key}`);
      return;
    }

    return val;
  }
}
