@if (inline()) {
  <div class="spinner-wave {{ color() }} {{ size() }}" aria-label="Laster.." title="Laster..">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
} @else {
  <div aria-label="Laster.." class="loader" id="secondary-loader" title="Laster..">
    <div class="center">
      <div class="inner-loader">
        <h1><ng-content /></h1>
        <div class="spinner-wave">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <button (click)="cancelRouting()" class="btn btn-light" type="button">Avbryt</button>
      </div>
    </div>
  </div>
}
