import { Property } from '@models/property/property';

export type IPropertyState = {
  property: Property;
  subProperties?: PropertyState[];
  visible: boolean;
};

export type UpdatedPropertiesInterface = {
  list: PropertyState[];
  property?: Property;
  type: string;
};

export class PropertyState {
  private property: Property;
  private subProperties?: PropertyState[];
  private visible: boolean;

  constructor(propertyState: IPropertyState) {
    this.property = propertyState.property;
    this.visible = propertyState.visible;

    if (typeof propertyState.subProperties !== 'undefined') {
      this.subProperties = propertyState.subProperties;
    } else {
      this.subProperties = [];
    }
  }

  getProperty(): Property {
    return this.property;
  }

  getSubProperties(): PropertyState[] {
    return this.subProperties;
  }

  getVisibility(): boolean {
    return this.visible;
  }

  setVisibility(state: boolean): void {
    this.visible = state;
  }
}
