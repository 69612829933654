import { inject, Injectable } from '@angular/core';
import { MapConfig } from '@config/map.config';
import { OpenlayersHelper } from '@helpers/openlayers.helper';
import { getTopLeft } from 'ol/extent';
import ImageTile from 'ol/ImageTile';
import Image from 'ol/layer/Image';
import Layer from 'ol/layer/Layer';
import Tile from 'ol/layer/Tile';
import Map from 'ol/Map';
import { get as getProj } from 'ol/proj';
import ImageWMS from 'ol/source/ImageWMS';
import sourceWMTS from 'ol/source/WMTS';
import WMTS from 'ol/tilegrid/WMTS';
import { map as rxMap } from 'rxjs/operators';
import { ConfigService } from 'src/app/core/services/config.service';

declare const proj4: any;
// it should be:
// import { defs } from 'proj4';
// or
// import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';

@Injectable()
export class BackgroundMapsService {
  private _cs = inject(ConfigService);

  private bgPath = 'config/layerconfig/backgroundlayers.json';
  private extents: number[][] = [];
  private formats: string[] = [];
  // more general, including all base layers
  private ids: string[] = [];
  private layers: string[] = [];
  private loadingTiles = 0;
  private projections: string[] = [];
  private resolutions: number[][] = [];
  private sbg: Record<string, Image<ImageWMS>> = {};
  private selectedBackgroundMap = 'graatoner';
  private sets: string[] = [];
  private styles: string[] = [];
  private tileGrids: any[] = [];

  private toLayerId: Record<string, string> = {
    farger: 'topo',
    graatoner: 'topograatone',
    // raster: 'toporaster',
  };

  private urls: string[] = [];

  constructor() {
    proj4.defs('EPSG:25832', '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
    proj4.defs('EPSG:25833', '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
    proj4.defs('EPSG:25835', '+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
    register(proj4);
  }

  // WMSer brukes for å supplere treige WMTSer, de kan fjernes etter hvert:
  private createWMS(id: string): Image<ImageWMS> {
    const lid = this.toLayerId[id];
    return new Image({
      properties: { id: `${id}WMS` },
      source: new ImageWMS({
        params: { FORMAT: 'image/png', LAYERS: lid },
        projection: 'EPSG:3857', // needed on 3D
        url: `https://wms.geonorge.no/skwms1/wms.${lid}`,
      }),
      visible: false,
      zIndex: 0,
    });
  }

  /**
   * Replaces utm code in a string where different codes are present
   * @param  {string} name string to be updated with right utm code
   * @param  {number} srid right utm to use
   * @return {string}      updated string
   */
  private replaceUtm(name: string, srid: number): string {
    if (name === undefined) {
      return '';
    }
    let replaced = name;
    if (srid === 25832) {
      replaced = replaced.replace('utm33', 'utm32');
      replaced = replaced.replace('utm35', 'utm32');
      replaced = replaced.replace('UTM33', 'UTM32');
      replaced = replaced.replace('UTM35', 'UTM32');
      return replaced;
    } else if (srid === 25833) {
      replaced = replaced.replace('utm32', 'utm33');
      replaced = replaced.replace('utm35', 'utm33');
      replaced = replaced.replace('UTM32', 'UTM33');
      replaced = replaced.replace('UTM35', 'UTM33');
      return replaced;
    } else if (srid === 25835) {
      replaced = replaced.replace('utm32', 'utm35');
      replaced = replaced.replace('utm33', 'utm35');
      replaced = replaced.replace('UTM32', 'UTM35');
      replaced = replaced.replace('UTM33', 'UTM35');
      return replaced;
    }
    return name;
  }

  private substitute(id: string, upd: number, retryTile: false | ImageTile) {
    this.loadingTiles += upd;
    // console.log(this.loadingTiles);
    const current = this.selectedBackgroundMap === id;
    if (retryTile) setTimeout(() => retryTile.load(), 10000);
    else if (this.toLayerId[id]) this.sbg[id].setVisible(this.loadingTiles > 0 && current);
  }

  /**
   * Generates a list of all the bg layers that are in the json list
   * @param  {number} srid utm zone to assign to the layers
   * @return {list}      bg layers in a list
   */
  getBgGroup(srid: number) {
    let mapExtent = [-2000000, 3500000, 3545984, 9045984];
    if (srid === 25832) {
      mapExtent = [-2000000, 3500000, 3545984, 9045984];
    } else if (srid === 25833) {
      mapExtent = [-2500000.0, 3500000.0, 3045984.0, 9045984.0];
    } else if (srid === 25835) {
      mapExtent = [-3500000, 3500000, 2045984, 9045984];
    }

    const projection = getProj('EPSG:' + srid.toString());
    const utm = srid.toString().slice(-2);

    // let group: layer.Group;
    const group: Layer[] = [];
    // empty every arrays, otherwise they will be not empty after a new search
    this.ids = [];
    this.urls = [];
    this.layers = [];
    this.sets = [];
    this.formats = [];
    this.styles = [];
    this.projections = [];
    this.tileGrids = [];
    this.extents = [];
    this.resolutions = [];

    return this._cs.getConfig(this.bgPath).pipe(
      rxMap(json => {
        const resolutions = json['common_options'].resolutions;
        const matrixIds = new Array(resolutions.length);
        for (let z = 0; z < resolutions.length; ++z) {
          matrixIds[z] = z;
        }

        const tg = new WMTS({
          matrixIds: matrixIds,
          origin: getTopLeft(mapExtent),
          resolutions: resolutions,
        });
        const tg_nib = new WMTS({
          matrixIds: matrixIds,
          origin: getTopLeft(mapExtent),
          resolutions: resolutions,
        });

        for (const key of Object.keys(json['backgroundlayers'])) {
          const bg = json['backgroundlayers'][key];
          let s = null;
          if (bg.source) {
            s = new sourceWMTS({
              extent: mapExtent,
              format: bg.source.format,

              layer: bg.id === MapConfig.ORTHO ? this.replaceUtm(bg.source.layer, srid) : bg.source.layer,

              // nib layer is dependent on utm
              matrixSet: bg.id === MapConfig.ORTHO ? bg.source.matrixSet : `utm${utm}n`,
              projection: projection,
              style: bg.source.style,
              tileGrid: tg,
              url: bg.source.url,
            } as any);

            // Vis WMS mens WMTS driver å laste ned:
            if (this.toLayerId[bg.id]) this.sbg[bg.id] = this.createWMS(bg.id);
            s.on('tileloadstart', () => this.substitute(bg.id, 1, false));
            s.on('tileloadend', () => this.substitute(bg.id, -1, false));
            s.on('tileloaderror', e => this.substitute(bg.id, -1, e.tile));

            if (bg.source.type) {
              const type = bg.source.type.toLowerCase();
              switch (type) {
                case 'imagewms':
                  s = new ImageWMS(bg.source);
                  s.set('type', 'ImageWMS', true);
                  break;
                default:
              }
            }

            // save all properties to be able to create a new source with updated url (with token)
            if (bg.id !== 'ingen') {
              this.ids.push(bg.id);
              if (bg.id === MapConfig.ORTHO) {
                this.tileGrids.push(tg_nib);
                this.sets.push(bg.source.matrixSet); // default028mm
              } else {
                this.tileGrids.push(tg);
                this.sets.push(`utm${utm}n`); // utm32n, utm33n or utm35n
              }
              this.urls.push(bg.source.url);
              this.layers.push(this.replaceUtm(bg.source.layer, srid));
              this.formats.push(bg.source.format);
              this.styles.push(bg.source.style);
              this.projections.push(projection.getCode());
              this.extents.push(mapExtent);
              this.resolutions.push(resolutions);
            }
          }
          let olLayer: Layer = new Tile({
            id: bg.id,
            source: s,
            title: bg.ui_options.name,
            type: 'base',
          } as any);

          if (bg.source.type) {
            const type = bg.source.type.toLowerCase();
            switch (type) {
              case 'imagewms':
                olLayer = new Image({
                  id: bg.id,
                  params: bg.params,
                  source: s,
                  title: bg.ui_options.name,
                  type: 'base',
                } as any);
                break;
              default:
            }
          }

          const minScale = bg.ui_options.minScale;
          if (minScale !== undefined) {
            // this.layer.setMaxResolution(200);
            olLayer.setMaxResolution(OpenlayersHelper.getResolution(minScale));
          }
          const maxScale = bg.ui_options.maxScale;
          if (maxScale !== undefined) {
            olLayer.setMinResolution(20);
          }
          if (bg.skipUrlUpdate) {
            olLayer.set('skipUrlUpdate', true, true);
          }

          // Set the z-index to avoid undefined in printing logic
          olLayer.setZIndex(0);

          group.push(olLayer);

          if (this.sbg[bg.id]) group.push(this.sbg[bg.id]); // substitute WMS
        }
        return group;
      }),
    );
  }

  getSelected(): string {
    return this.selectedBackgroundMap;
  }

  getSubstituteWMS(id: string): Image<ImageWMS> {
    return this.sbg[id];
  }

  setSelected(id: string): void {
    const previous = this.selectedBackgroundMap;
    if (this.toLayerId[previous]) this.sbg[previous].setVisible(false);
    this.selectedBackgroundMap = id;
  }

  /**
   * Each url has to be updated with obtained token
   * @param  {string} token to append to url
   * @param  {number} srid  in case url contains wrong utm code
   * @param  {Map}    map   to update with new nib layer
   * @return {void}       nothing
   */
  updateUrls(token: string, srid: number, map: Map) {
    // if (this.nibSource === undefined) { return; }
    this.urls.forEach((url, i) => {
      let tg = null;
      if (this.ids[i] === MapConfig.ORTHO) {
        const idx = url.indexOf('?gkt=');
        if (idx !== -1) {
          // just upd token:
          url = url.slice(0, idx) + '?gkt=' + token;
        } else {
          url = this.replaceUtm(url, srid);
          url += '?gkt=' + token;
        }
      }

      if (this.ids[i] !== MapConfig.ORTHO) {
        // not nib
        // const srid326 = srid.toString();
        const resolutions = this.resolutions[i];
        const matrixIds = new Array(resolutions.length);
        for (let z = 0; z < resolutions.length; ++z) {
          matrixIds[z] = z;
        }
        tg = new WMTS({
          matrixIds: matrixIds,
          origin: getTopLeft(this.extents[i]),
          resolutions: resolutions,
        });
      } else {
        // nib
        tg = this.tileGrids[i];
      }

      let updSource: any = new sourceWMTS({
        format: this.formats[i],
        layer: this.layers[i],
        // nib layer is dependent on utm
        matrixSet: this.sets[i],
        projection: this.projections[i],
        style: this.styles[i],
        tileGrid: tg,
        url: url,
      });

      updSource.on('tileloadstart', () => this.substitute(this.ids[i], 1, false));
      updSource.on('tileloadend', () => this.substitute(this.ids[i], -1, false));
      updSource.on('tileloaderror', e => this.substitute(this.ids[i], -1, e.tile));

      // Workaround
      updSource['extent'] = this.extents[i];

      const oldLayer = map?.getAllLayers().find(x => x.get('id') === this.ids[i]);
      if (oldLayer === undefined) {
        return;
      }

      const old = oldLayer.getProperties();
      if (old['skipUrlUpdate']) {
        return;
      }

      const oldType = oldLayer.getSource()?.getProperties();
      if (oldType['type']) {
        const type = oldType['type'].toLowerCase();
        switch (type) {
          case 'imagewms':
            updSource = new ImageWMS({
              format: this.formats[i],
              layer: this.layers[i], // nib layer is dependent on utm
              params: old['source'].getParams(),
              style: this.styles[i],
              type: oldType['type'],
              url: url,
            } as any);
            break;
          default:
        }
      }
      oldLayer.setSource(updSource);
    });
    return;
  }
}
