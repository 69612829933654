import Feature from 'ol/Feature';
import LineString from 'ol/geom/LineString';
import Polygon from 'ol/geom/Polygon';

export class MiscTools {
  static DEFAULT_ALERT = 'Tjenesten er ikke tilgjengelig for øyeblikket. Vennligst prøv igjen senere. ';
  // Får ikke hentet data fra LiB, og det ligger ikke data i cachen. Løsningen går da mot Matrikkelen.
  static NO_LIB_NO_CACHE =
    'Vi får ikke kontakt med Landbruksdirektoratet sin database (LIB). ' +
    ' Vi får derfor ikke oversikt over landbrukseiendommen,' +
    ' og henter kun data for valgt grunneiendom.';
  // public static NO_LIB_OK_CACHE_2_2 = 'for å vise Gårdskart. Eventuelle endringer i LiB etter denne datoen vises ikke i kartet.';
  // får ikke hentet data fra LiB, men henter fra cachen. Melding med siste nedlastede dato for data fra LiB må vises.
  static NO_LIB_OK_CACHE =
    'Vi får ikke kontakt med Landbruksdirektoratet sin database (LIB). ' +
    ' Vi henter derfor data fra tidligere nedlastede data ' +
    'for å vise Gårdskart. Eventuelle endringer i LiB etter denne datoen vises ikke i kartet. Gjelder ';
  // Får ikke hentet data fra Matrikkelen, og data finnes ikke i cachen.
  static NO_MATRIKKEL_NO_CACHE =
    'Vi får ikke kontakt med Matrikkelen. Vi kan derfor ikke lage Gårdskart for denne eiendommen.' +
    ' Vennligst prøv igjen senere';
  //  public static NO_MATRIKKEL_OK_CACHE_2_2 = 'for å vise Gårdskart. Eventuelle endringer i Matrikkelen etter denne datoen' +
  //  ' vises ikke i kartet.';
  // Får ikke hentet data fra Matrikkelen, men data finnes i cachen. Melding med siste nedlastede dato for data fra Matrikkelen må vises.
  static NO_MATRIKKEL_OK_CACHE =
    'Vi får ikke kontakt med Matrikkelen. Vi henter derfor data fra tidligere nedlastede data ' +
    'for å vise Gårdskart. Eventuelle endringer i Matrikkelen etter denne datoen vises ikke i kartet. Gjelder ';

  // public static isEmptyObject (obj: any) {
  //   if (!MiscTools.isIterable(obj)) {
  //     return false;
  //   }
  //   return !Object.length;
  // }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  static check_status_Lib_matrikkel(data: any): { lib: boolean; matrikkel: boolean } {
    const lib_or_matrikkel_status = { lib: true, matrikkel: true };
    if (data && data.subSystemCurrentStateType) {
      for (const status of data.subSystemCurrentStateType) {
        if (status.value !== 'OK') {
          if (status.name === 'LIB_DOWNLOAD_HANDLER_SINGLE_FARM' || status.name === 'LIB_SINGLE_FARM') {
            lib_or_matrikkel_status.lib = false;
          } else if (status.name === 'MATRIKKEL_DOWNLOAD_HANDLER' || status.name === 'MATRIKKEL') {
            lib_or_matrikkel_status.matrikkel = false;
          }
        }
      }
    }
    return lib_or_matrikkel_status;
  }

  /**
   * Format area output.
   * @param {Polygon} polygon The polygon.
   * @return {string} Formatted area.
   */
  static formatArea(polygon: Polygon): string {
    const area = polygon.getArea();
    let output: string;
    if (area > 100000) {
      output = Math.round((area / 1000000) * 100) / 100 + ' ' + 'km\u00B2';
    } else {
      output = Math.round((area * 100) / 100) + ' ' + 'm\u00B2';
    }
    return output;
  }

  /**
   * Format length output.
   * @param {LineString} line The line.
   * @return {string} The formatted length.
   */
  static formatLength(line: LineString): string {
    const length = line.getLength();
    let output: string;
    if (length > 1000) {
      output = Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
    } else {
      output = Math.round((length * 100) / 100) + ' ' + 'm';
    }
    return output;
  }

  /**
   * Returns a delay value (in ms) based on the counter value.
   * Counting from 0 to apx.  426 will result in 30 minutes of total delay.
   * @param  {number} counter ith poll request made to the fallBackUrl
   * @return {number}         milliseconds to delay
   */
  static getDelay(counter: number): number {
    if (counter < 5) {
      return 1500; // 1,5 s
    } else if (counter < 30) {
      return 10000; // 10 s
    } else if (counter < 100) {
      return 60000; //  1 min
    } else {
      return 900000; // 15 min
    }
  }

  static isIterable(obj: any) {
    // checks for null and undefined
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
  }

  /**
   * Prepend leading zeroes to number represented as string
   * to achieve desired length.
   *
   * @param  {string} number number represented as string
   * @param  {number} size   size/length of the desired string
   * @return {string}        number prepended with amount of zeroes to achieve
   *                         desired length of the string
   */
  static padWithZero(number: string, size: number): string {
    let s = number + '';
    while (s.length < size) {
      s = '0' + s;
    }
    return s;
  }

  /**
   * check_status_Lib_matrikkel, we have 2 states for both LIB and MATRIKKEL
   * @param  {object} data from backend
   * @return {lib: boolean, matrikkel: boolean}   we set status to false if one of the states is false
   */
  static subpropertyIdFromFeature(feature: Feature) {
    const k = feature.get('municipality_nr');
    const komm = this.padWithZero(k, 4);
    const gnr = feature.get('gnr');
    const bnr = feature.get('bnr');
    const fnr = feature.get('fnr');
    return komm + '-' + [gnr, bnr, fnr].join('/');
  }

  /**
   * Generate a random token id
   * @param  {number} length length of token id
   * @return {string}        random token with specified length
   */
  static tokenGenerator(length: number): string {
    const maxlength = 50;
    let token = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < Math.min(Math.max(length, 0), maxlength); i++) {
      token += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return token;
  }
}
