<div class="panel-header">
  <h4>
    Samtalerobot
    <i
      (click)="showChatbotPopover = !showChatbotPopover"
      (keyup.enter)="showChatbotPopover = !showChatbotPopover"
      [isOpen]="showChatbotPopover"
      [popover]="
        'Ask er en samtalerobot som svarer på spørsmål om funksjonalitet og innhold i Gårdskart-tjenesten. Den har kun kunstig intelligens, så den kan misforstå og gi feil råd.'
      "
      class="icon bi bi-question-circle info-button"
      tabindex="0"
      title="Informasjon om samtaleroboten"
    ></i>
  </h4>
  <i
    (click)="purgeConversation()"
    (keyup.enter)="purgeConversation()"
    [class.dim]="conversation?.length < 2"
    [title]="conversation?.length ? 'Tøm samtale' : 'Ingen samtale startet'"
    class="icon bi bi-trash purge-button"
    tabindex="0"
  ></i>
  <i
    (click)="toggleChatbot.emit(undefined)"
    (keyup.enter)="toggleChatbot.emit(undefined)"
    class="icon bi bi-arrow-up-circle close-button"
    tabindex="0"
    title="Skjul Chatbot"
  ></i>
</div>

<div #conversationPanelRef class="panel-conversation p-2 mb-2">
  <ul #messagesRef class="chatbot-messages">
    @for (msg of conversation; track $index) {
      <li (focus)="focusMsg($event)" [class]="msg.variant.toString()" tabindex="0">
        <markdown [innerHTML]="msg.message" />
      </li>
    }
    @if (loading) {
      <li class="incoming"><gk-spinner [inline]="true" color="black" size="s" /></li>
    }
  </ul>
</div>

<form (submit)="send($event)" class="panel-footer">
  <div class="input-group">
    <input
      #inputRef
      (keydown.enter)="handleEnterKey($event)"
      (keyup.arrowUp)="restorePreviousPrompt()"
      [title]="
        previousPrompt?.length ? 'Skriv her, eller trykk pil opp for å fortsette på forrige spørsmål' : 'Skriv her...'
      "
      class="form-control"
      placeholder="Skriv her..."
      type="text"
    />
    <button class="btn btn-outline-secondary" id="button-addon2" type="submit">Send</button>
  </div>
</form>
