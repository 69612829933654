import { inject, Injectable } from '@angular/core';
import { ChatbotMessagePayloadType } from '@app-types/chatbot/chatbot-message-payload.type';
import { ChatbotMessageResponseType } from '@app-types/chatbot/chatbot-message-response.type';
import { ApiConfig } from '@config/api.config';
import { RequestService } from '@services/request.service';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GaardskartAiService {
  #requestService = inject(RequestService);
  #threadId = '';

  clearThreadId() {
    this.#threadId = '';
  }

  postPrompt(message: string): Observable<ChatbotMessageResponseType> {
    const payload: ChatbotMessagePayloadType = {
      message,
    };
    if (this.#threadId?.length > 0) {
      payload.thread_id = this.#threadId;
    }

    return this.#requestService.post<ChatbotMessageResponseType>('', payload, {}, {}, ApiConfig.proxiedAiUrl).pipe(
      tap(res => {
        this.#threadId = res.thread_id;
      }),
    );
  }
}
