import { ApiConfig } from '@config/api.config';
import { MapTools } from '@tools/map.tools';
import { asArray, Color } from 'ol/color';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';

/**
 *  MapStyles for imported files
 */
export function importedFileStyles(fileFormat: string) {
  return new Style({
    image: new Circle({
      fill: new Fill({
        color: [255, 0, 0, 0.7],
      }),
      radius: 5,
      stroke: new Stroke({
        color: [255, 255, 255, 1],
        width: 2,
      }),
    }),
    stroke: new Stroke({
      color: fileFormat === 'kml' ? [0, 0, 255, 0.7] : [255, 0, 0, 0.7],
      width: 3,
    }),
  });
}

/**
 *  MapStyles for property Borders
 */
export const ownerStyles = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Ff_Style: new Style({
    stroke: new Stroke({ color: '#f00', width: 3 }),
    zIndex: 5,
  }),
  // eslint-disable-next-line @typescript-eslint/naming-convention
  M_Style: new Style({
    stroke: new Stroke({ color: '#f99', width: 3 }),
    zIndex: 4,
  }),
  // eslint-disable-next-line @typescript-eslint/naming-convention
  S_Style: new Style({
    stroke: new Stroke({ color: '#006400', width: 3 }),
    zIndex: 6,
  }),
  // eslint-disable-next-line @typescript-eslint/naming-convention
  T_Style: new Style({
    stroke: new Stroke({ color: '#ba55d3', width: 3 }),
    zIndex: 7,
  }),
};

/**
 * Mapstyle to indicate area (extra fill)
 */
export const indicateAreaStyle = new Style({
  fill: new Fill({ color: [233, 78, 15, 0.6] }),
});

export const lineDashStyle = new Style({
  stroke: new Stroke({ color: '#fff', lineDash: [4, 16], width: 3 }),
  zIndex: 8,
});

export function propertyStyleAll(feature: Feature) {
  if (feature.get('show') === false) {
    return null;
  }
  const ownership = feature.get('ownership');
  let style = null;
  if (ownership === 'Ff') {
    style = ownerStyles.Ff_Style;
  } else if (ownership === 'S' || ownership === 'Sx') {
    style = ownerStyles.S_Style;
  } else if (ownership === 'T') {
    style = ownerStyles.T_Style;
  } else if (ownership === 'M' || ownership === 'F') {
    style = ownerStyles.M_Style;
  } else {
    return style;
  }
  const indicateArea = feature.get('indicate');
  if (indicateArea === 'ON') {
    return [style, indicateAreaStyle];
  }
  const added = feature.get('added');
  if (added === 'true') {
    // Add the style to indicate the added property
    return [style, lineDashStyle];
  }

  return style;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function propertyStyleAll_notFarm(feature: Feature) {
  if (feature.get('show') === false) {
    return null;
  }
  const ownershipproperty = feature.get('ownershipproperty');
  let ostyle = null;
  if (ownershipproperty === 'Ff') {
    ostyle = ownerStyles.Ff_Style;
  } else if (ownershipproperty === 'S' || ownershipproperty === 'Sx') {
    ostyle = ownerStyles.S_Style;
  } else if (ownershipproperty === 'T') {
    ostyle = ownerStyles.T_Style;
  } else if (ownershipproperty === 'M' || ownershipproperty === 'F') {
    ostyle = ownerStyles.M_Style;
  } else {
    return null;
  }
  const indicateArea = feature.get('indicate');
  if (indicateArea === 'ON') {
    return [ostyle, indicateAreaStyle];
  }
  const added = feature.get('added');
  if (added === 'true') {
    // Add the style to the added property
    return [ostyle, lineDashStyle];
  }
  return ostyle;
}

/**
 * Stylefunction for features with ordinary ownerships
 * @param  feature    geofeature
 * @return            a style for the feature
 */
export function propertyStyleDefault(feature: Feature) {
  if (feature.get('show') === false) {
    return null;
  }
  const ownership = feature.get('ownership');
  if (ownership === 'M' || ownership === 'F') {
    // ownerStyles.M_Style;
    const indicateArea = feature.get('indicate');
    if (indicateArea === 'ON') {
      // Add the style to indicate the area
      return [indicateAreaStyle, ownerStyles.M_Style];
    }
    const added = feature.get('added');
    if (added === 'true') {
      // Add the style to indicate the area
      return [ownerStyles.M_Style, lineDashStyle];
    }

    return ownerStyles.M_Style;
  } else {
    return null;
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function propertyStyleDefault_notFarm(feature: Feature) {
  if (feature.get('show') === false) {
    return null;
  }
  const ownershipproperty = feature.get('ownershipproperty');
  if (ownershipproperty === 'M' || ownershipproperty === 'F') {
    const ostyle = ownerStyles.M_Style;
    const indicateArea = feature.get('indicate');
    if (indicateArea === 'ON') {
      return [ostyle, indicateAreaStyle];
    }
    const added = feature.get('added');
    if (added === 'true') {
      // Add the style to indicate the added property
      return [ostyle, lineDashStyle];
    }

    return ostyle;
  } else {
    return null;
  }
}

export function textStyleDefault(feature: Feature, resolution: number) {
  if (feature.get('show') === false) {
    return null;
  }

  return new Style({
    text: new Text({
      fill: new Fill({ color: '#4d4d4d' }),
      font: 'bold italic 17px helvetica',
      stroke: new Stroke({
        color: 'rgba(255,255,255, 0.6)',
        width: 6,
      }),
      text: MapTools.getTextGBnr(feature, resolution),
      textAlign: 'left',
      textBaseline: 'ideographic',
    }),
  });
}

export function textStyleHideUndecided(feature: Feature, resolution: number, showAll: boolean) {
  if (feature.get('show') === false) {
    return null;
  }

  // If ownership is undecided
  if (ApiConfig.specialOwners.includes(feature.get('ownership')) && !showAll) {
    // Hide these until showAll overrides it
    return null;
  }

  // Everything else gets default style
  return textStyleDefault(feature, resolution);
}

/**
 *  MapStyles for property driftsenterstyle
 */
export const driftsenterStyle = new Style({
  image: new Circle({
    fill: new Fill({
      color: 'rgba(255, 0, 0, 0.7)',
    }),
    radius: 6,
    stroke: new Stroke({
      color: 'rgba(0, 0, 0, 1)',
      width: 2,
    }),
  }),
});

/**
 * Will generate apropriate area-lable, depending on feature and resolution
 * @param {Feature} feature The feature to style. Must have an area-property
 * @param {Number}  resolution
 * @return {string} The area of the feature as a string formatted with one decimal in the fractional part
 */
const getIcon = (feature: Feature, resolution: number): Icon => {
  const arType = feature.get('img');
  let image: Icon = null;
  let scale = 0.5;

  if (arType) {
    const areal = feature.get('areal');

    if (resolution > 4) {
      scale = 0.3;
    } else if (resolution <= 4 && resolution > 2) {
      scale = 0.4;
    }
    if (resolution > 10 || areal < 0.5) {
      return image;
    }
    if (
      (areal >= 100 && resolution < 10) ||
      (areal >= 5 && areal <= 100 && resolution < 5) ||
      (areal >= 1 && areal < 5 && resolution < 2) ||
      (areal <= 1 && resolution < 0.6)
    ) {
      image = new Icon({
        anchor: [0.5, 0.8],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: scale,
        src: 'img/arstat/' + arType + '_gul.png',
      });
    }
  }

  return image;
};

/**
 * Will generate apropriate area-lable, depending on feature and resolution
 * @param {Feature} feature The feature to style. Must have an area-property
 * @param {Number}  resolution
 * @return {string}    The area of the feature as a string formatted with one decimal in the fractional part
 */
const getText = function (feature: Feature, resolution: number): string {
  let text = '';
  const areal = feature.get('areal');
  // no lable if resolution is more than 10 or tiny area
  if (resolution > 10 || areal < 0.05) {
    return text;
  }
  if (
    (areal >= 200 && resolution < 5) ||
    (areal >= 5 && areal <= 200 && resolution < 4) ||
    (areal >= 1 && areal < 5 && resolution < 2) ||
    (areal <= 1 && resolution < 0.6)
  ) {
    text = areal.toFixed(1);
  }
  return text;
};

const setTranspaency = (inColor: Color | string, inTransparency: number) => {
  const color = asArray(inColor);
  color[3] = inTransparency;
  return color;
};

export function gardskartortostyle(feature: Feature, resolution: number) {
  if (feature.get('show') === false) {
    return null;
  }
  // const color = feature.get('color');
  const lineColor = 'rgba(255, 255, 0, 0.7)';
  const textColor = '#ffff00';
  // let fill = new Fill({ color: color });
  const ostyle = new Style({
    // fill: fill,
    stroke: new Stroke({ color: lineColor, width: 1 }),
    text: new Text({
      fill: new Fill({ color: textColor }),
      font: 'normal italic 13px helvetica',
      offsetY: 15,
      text: getText(feature, resolution),
      textAlign: 'center',
      textBaseline: 'ideographic',
    }),
  });

  const image = getIcon(feature, resolution);
  const iconstyle = new Style({
    geometry: function (geoFeature) {
      // return the coordinates of the first ring of the polygon
      const poly: Polygon = geoFeature.getGeometry() as Polygon;
      const coordinates = poly.getInteriorPoint().getCoordinates();
      return new Point(coordinates);
    },
    image: image,
  });
  return [ostyle, iconstyle];
}

export function gardskartotherstyle(feature: Feature, resolution: number) {
  if (feature.get('show') === false) {
    return null;
  }
  const color = feature.get('color');
  const transpcolor = setTranspaency(color, 0.5);
  return new Style({
    fill: new Fill({ color: transpcolor }),
    stroke: new Stroke({ color: 'rgba(255, 255, 0, 0.7)', width: 1 }),
    text: new Text({
      fill: new Fill({ color: '#4d4d4d' }),
      font: 'normal italic 13px helvetica',
      text: getText(feature, resolution),
      textAlign: 'center',
      textBaseline: 'ideographic',
    }),
  });
}

export function gardskartstyle(feature: Feature, resolution: number) {
  if (feature.get('show') === false) {
    return null;
  }
  const color = feature.get('color');
  return new Style({
    fill: new Fill({ color: color }),
    stroke: new Stroke({ color: 'rgba(0, 0, 0, 0.7)', width: 1 }),
    text: new Text({
      fill: new Fill({ color: '#4d4d4d' }),
      font: 'normal italic 13px helvetica',
      text: getText(feature, resolution),
      textAlign: 'center',
      textBaseline: 'ideographic',
    }),
  });
}
