<div class="login-menu">
  @if (isAuthenticated$ | async; as isAuthenticated) {
    <div class="btn-group" role="group">
      <button
        [title]="userName"
        aria-expanded="false"
        aria-haspopup="true"
        class="btn btn-min-side-header dropdown-toggle"
        data-bs-target="#user-menu"
        data-bs-toggle="dropdown"
        id="minSideGroup"
        type="button"
      >
        {{ displayName }}
      </button>
      <ul
        aria-labelledby="minSideGroup"
        class="user-menu dropdown-menu dropdown-menu-right"
        data-bs-theme="light"
        id="user-menu"
      >
        <li>
          <a (click)="goMinSide()" (keyup.enter)="goMinSide()" class="dropdown-item" role="menuitem" tabindex="0">
            <i class="bi bi-person"></i>
            Min side
          </a>
        </li>
        <li>
          <a
            (click)="displayLogOutDialog()"
            (keyup.enter)="displayLogOutDialog()"
            class="dropdown-item"
            role="menuitem"
            tabindex="0"
          >
            <i class="bi bi-lock"></i>
            Logg ut
          </a>
        </li>
      </ul>
    </div>
  } @else {
    <div class="btn-group">
      <button (click)="login()" class="btn btn-min-side-header btn-login">
        @if (showSpinner) {
          <div class="loader" title="Logger inn..."></div>
        }
        @if (!showSpinner) {
          <div>Logg inn</div>
        }
      </button>
    </div>
  }
</div>

<gk-modal
  #logOutDialog
  [hideFooter]="true"
  [showIcon]="false"
  class="logOutDialog"
  data-bs-theme="light"
  dialogMode="warning"
  header="Vil du logge ut?"
>
  <div class="text-right">
    <button (click)="hideLogoutConfirm()" class="btn btn-gk-warning" id="noLogout" type="button">Nei</button>
    <button #confirmLogout (click)="logoutConfirmed()" class="btn btn-gk-warning" type="button">Ja</button>
  </div>
</gk-modal>
