import { HttpClient, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  private _httpClient = inject(HttpClient);

  #createURI(path: string, params: object = {}, queryParams: object = {}, overrideBaseUrl?: string): string {
    const baseUrl = overrideBaseUrl || environment.gkUrl;
    const pathArray = path.split('/');

    // Replace params with actual values
    pathArray.forEach((segment: any, index: number) => {
      if (segment.charAt(0) === ':') {
        pathArray[index] = params[segment.substring(1)];
      }
    });

    // Use URL Class to set params properly
    const url = new URL(baseUrl + pathArray.join('/'), environment.gkUrl);
    for (const [key, value] of Object.entries(queryParams)) {
      if (value !== undefined) {
        url.searchParams.set(key, value);
      }
    }

    // Compile back as string
    const paramsStr = url.searchParams.toString()?.length ? '?' + url.searchParams.toString() : '';
    return baseUrl + pathArray.join('/') + paramsStr;
  }

  delete<T>(path: string, params: object = {}, queryParams: object = {}): Observable<T> {
    return this._httpClient.delete(this.#createURI(path, params, queryParams)).pipe(
      map((response: object) => {
        return response as T;
      }),
    );
  }

  get<T>(path: string, params: object = {}, queryParams: object = {}): Observable<T> {
    return this._httpClient
      .get(this.#createURI(path, params, queryParams), {
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          if (response.status === 204) {
            return {} as T;
          } else {
            return response.body as T;
          }
        }),
      );
  }

  patch<T>(path: string, payload: any, params: object = {}, queryParams: object = {}): Observable<T> {
    return this._httpClient.patch(this.#createURI(path, params, queryParams), payload).pipe(
      map((response: object) => {
        return response as T;
      }),
    );
  }

  post<T>(
    path: string,
    payload: any,
    params: object = {},
    queryParams: object = {},
    overrideBaseUrl?: string,
  ): Observable<T> {
    return this._httpClient.post(this.#createURI(path, params, queryParams, overrideBaseUrl), payload).pipe(
      map((response: object) => {
        return response as T;
      }),
    );
  }

  put<T>(path: string, payload: any, params: object = {}, queryParams: object = {}): Observable<T> {
    return this._httpClient.put(this.#createURI(path, params, queryParams), payload).pipe(
      map((response: object) => {
        return response as T;
      }),
    );
  }
}
