<div #modal="bs-modal" bsModal="bsmodal" class="modal fade" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-md" role="document">
    <div [ngClass]="dialogModes[dialogMode]?.['class']" class="modal-content">
      <div class="modal-header panel-heading justify-content-between">
        <h4 class="modal-title">
          @if ((showIcon === true && dialogModes[dialogMode].icon !== '') || customIcon !== '') {
            <span [ngClass]="customIcon === '' ? dialogModes[dialogMode].icon : customIcon" class="icon-title"> </span>
          }
          {{ header() || title() }}
        </h4>
        <button (click)="hide()" aria-label="Close" class="close" title="Lukk dialog" type="button">&times;</button>
      </div>

      @if (hideBody !== true) {
        <div class="modal-body">
          {{ content() }}
          <ng-content />
        </div>
      }

      @if (hideFooter !== true) {
        <div class="modal-footer">
          <button (click)="modal.hide()" class="btn btn-light" data-dismiss="lgModal" type="button">
            {{ buttonText() }}
          </button>
        </div>
      }
    </div>
  </div>
</div>
