import { Component, ElementRef, inject, Input, input, OnChanges, SimpleChanges, viewChild } from '@angular/core';
import { ChatbotComponent } from '@components/chatbot/chatbot.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@Component({
  imports: [BsDropdownModule, ChatbotComponent],
  selector: 'gk-chatbot-button',
  styleUrl: './chatbot-button.component.scss',
  templateUrl: './chatbot-button.component.html',
})
export class ChatbotButtonComponent implements OnChanges {
  readonly align = input<'center' | 'left' | 'right'>('left');

  readonly toggleButton = viewChild('toggleRef', { read: ElementRef });

  @Input()
  show = false;

  nativeElement: HTMLElement = inject(ElementRef).nativeElement;

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.nativeElement?.children[0] || !this.toggleButton()?.nativeElement) {
      return;
    }

    if (changes['show']?.currentValue !== undefined && !changes['show']?.isFirstChange()) {
      this.toggle();
    }
  }

  /**
   * Show / hide the chatbot
   */
  toggle() {
    this.toggleButton()?.nativeElement.click();
    this.show = !this.show;
  }
}
