import { Teig } from '@models/property/teig';
import { MiscTools } from '@tools/misc.tools';

export class Grunneiendom {
  private _defaultTeigListe: Teig[];
  private _gid: string; // knr-gnr-bnr-fnr
  private _hovednummer: string; // knr-gnr/bnr/fnr
  private _isVisible: boolean;
  private _specialOwnerTeigListe: Teig[];
  private _srid: string;
  private _teigListe: Teig[];

  set defaultTeigListe(value: Teig[]) {
    this._defaultTeigListe = value;
  }

  get gid(): string {
    return this._gid;
  }

  set gid(value: string) {
    this._gid = value;
  }

  get hovednummer(): string {
    return this._hovednummer;
  }

  set hovednummer(value: string) {
    this._hovednummer = value;
  }

  get isVisible(): boolean {
    return this._isVisible;
  }

  set isVisible(value: boolean) {
    this._isVisible = value;
  }

  get readableGid(): string {
    const g = this._gid.split('-');
    const komm = MiscTools.padWithZero(g[0], 4);
    return komm + '-' + [g[1], g[2], g[3]].join('/');
  }

  set specialOwnerTeigListe(value: Teig[]) {
    this._specialOwnerTeigListe = value;
  }

  get srid(): string {
    return this._srid;
  }

  set srid(value: string) {
    this._srid = value;
  }

  get teigListe(): Teig[] {
    return this._teigListe;
  }

  set teigListe(value: Teig[]) {
    this._teigListe = value;
  }

  constructor() {
    this._isVisible = true;
  }

  getReadableGid(): string {
    const parts = this._gid.split('-');
    return parts[0] + '-' + parts[1] + '/' + parts[2] + '/' + parts[3];
  }

  getReadablePropertyNumber(): string {
    const parts = this._gid.split('-');
    return parts[1] + '/' + parts[2] + '/' + parts[3];
  }
}
