import { PropertyState, UpdatedPropertiesInterface } from '@models/property/property-state';
import { PropertyAction } from 'src/app/core/store/property/property-action';

export const ADD_PROPERTY = 'ADD_PROPERTY';
export const REMOVE_PROPERTY = 'REMOVE_PROPERTY';
export const SHOW_PROPERTY = 'SHOW_PROPERTY';
export const HIDE_PROPERTY = 'HIDE_PROPERTY';
export const SHOW_SUB_PROPERTY = 'SHOW_SUB_PROPERTY';
export const HIDE_SUB_PROPERTY = 'HIDE_SUB_PROPERTY';
export const RESET_ADDED_PROPERTIES = 'RESET_ADDED_PROPERTIES';

const DEFAULT = {
  list: new Array<PropertyState>(),
  type: 'INIT',
};

export function addedPropertyReducer(
  state: UpdatedPropertiesInterface = DEFAULT,
  action: PropertyAction,
): UpdatedPropertiesInterface {
  let index = -1;
  let subProperties: PropertyState[];
  const list = state.list;
  switch (action.type) {
    case ADD_PROPERTY:
      if (typeof action.subProperties !== 'undefined') {
        subProperties = new Array<PropertyState>();

        for (const property of action.subProperties) {
          subProperties.push(
            new PropertyState({
              property: property,
              visible: true,
            }),
          );
        }
      }

      return {
        list: list.concat(
          new PropertyState({
            property: action.property,
            subProperties: typeof action.subProperties !== 'undefined' ? subProperties : undefined,
            visible: true,
          }),
        ),
        property: action.property,
        type: action.type,
      };

    case HIDE_PROPERTY:
      index = list.findIndex(propertyFinder, action.property);

      if (index !== -1) {
        list[index].setVisibility(false);
      }

      return {
        list: list,
        property: action.property,
        type: action.type,
      };

    case HIDE_SUB_PROPERTY:
      for (const propertyState of list) {
        index = propertyState.getSubProperties().findIndex(propertyFinder, action.property);
        if (index !== -1) {
          propertyState.getSubProperties()[index].setVisibility(false);
          break;
        }
      }

      return {
        list: list,
        property: action.property,
        type: action.type,
      };

    case REMOVE_PROPERTY:
      index = list.findIndex(propertyFinder, action.property);
      if (index !== -1) {
        list.splice(index, 1);
      }
      return {
        list: list,
        property: action.property,
        type: action.type,
      };

    case RESET_ADDED_PROPERTIES:
      return DEFAULT;

    case SHOW_PROPERTY:
      index = list.findIndex(propertyFinder, action.property);

      if (index !== -1) {
        list[index].setVisibility(true);
      }

      return {
        list: list,
        property: action.property,
        type: action.type,
      };

    case SHOW_SUB_PROPERTY:
      for (const propertyState of list) {
        index = propertyState.getSubProperties().findIndex(propertyFinder, action.property);
        if (index !== -1) {
          propertyState.getSubProperties()[index].setVisibility(true);
          break;
        }
      }

      return {
        list: list,
        property: action.property,
        type: action.type,
      };

    default:
      return state;
  }
}

/**
 * Search function for Array<PropertyState>
 */
function propertyFinder(element: PropertyState) {
  const prop = element.getProperty();
  return (
    prop.komm === this.komm.toString() &&
    prop.gnr === this.gnr.toString() &&
    prop.bnr === this.bnr.toString() &&
    prop.fnr === this.fnr.toString()
  );
}
