import { AsyncPipe } from '@angular/common';
import { Component, ElementRef, inject, OnInit, viewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalComponent } from '@components/modal/modal.component';
import { AuthProvider } from '@providers/IAuthProvider';
import { ErrorService } from '@services/error.service';
import { MapService } from '@services/map.service';
import { UserService } from '@services/user.service';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  imports: [ModalComponent, AsyncPipe],
  selector: 'gk-bruker-info',
  styleUrls: ['./bruker-info.component.scss'],
  templateUrl: './bruker-info.component.html',
})
export class BrukerInfoComponent implements OnInit {
  private authProvider = inject(AuthProvider);

  private errorService = inject(ErrorService);
  private mapService = inject(MapService);
  private router = inject(Router);
  private userService = inject(UserService);
  readonly confirmLogout = viewChild<ElementRef>('confirmLogout');

  readonly logOutDialog = viewChild<ModalComponent>('logOutDialog');

  displayName = '';
  isAuthenticated$: Observable<boolean>;
  showSpinner = false;
  userName = '';

  deletedUser(deleteUser: boolean) {
    if (deleteUser) {
      this.authProvider.signOut();
    }
  }

  /**
   * When user click logout
   */
  displayLogOutDialog(): void {
    this.logOutDialog().show();
    this.confirmLogout().nativeElement.focus();
  }
  displayUserName() {
    let name = this.userName.trim();

    const emptyIndex = name.indexOf(' ');
    if (emptyIndex !== -1) {
      name = name.substr(0, emptyIndex);
    }

    if (name.length > 10) {
      this.displayName = name.substr(0, 7) + '...';
    } else {
      this.displayName = name;
    }
  }
  goMinSide() {
    // Delays navigation by 100ms to ensure dropdown box closes before going to a reuse route.
    setTimeout(() => {
      const returnUrl = this.router.url;
      void this.router.navigate(['/min-side'], { state: { returnUrl } });
    }, 100);
  }
  /**
   * When user click no
   */
  hideLogoutConfirm() {
    this.logOutDialog().hide();
  }

  /**
   * When user click login
   */
  login() {
    this.showSpinner = true;
    this.mapService
      .flushSessionData()
      .then(() => {
        this.showSpinner = false;
        this.authProvider.signIn(encodeURIComponent(this.router.url));
      })
      .catch(() => {
        this.errorService.putError('Kunne ikke logge inn. Vennligst prøv på nytt.');
        this.showSpinner = false;
      });
  }

  /**
   * When user click yes
   */
  logoutConfirmed() {
    this.authProvider.signOut(encodeURIComponent(this.router.url));
  }

  ngOnInit(): void {
    // Authentication
    this.isAuthenticated$ = this.authProvider.isAuthenticated$;
    this.userService.userData$.subscribe(x => {
      if (x) {
        this.userName = x.name;
        this.displayUserName();
      }
    });
  }
}
