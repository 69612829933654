import { isDevMode } from '@angular/core';
import { OpenlayersHelper } from '@helpers/openlayers.helper';
import { Options as KmlOptions } from 'ol/format/KML';

export class MapConfig {
  static readonly BLACK = 'rgba(0, 0, 0, 1)';
  static readonly DRIFTSSENTER = 'DRIFTSSENTER';
  static readonly DURATION_DEFAULT = 700; // Map view transition duration
  static readonly EIENDOMSGRENSE = 'EIENDOMSGRENSE';
  static readonly EXTENT_PADDING = [75, 75, 75, 75]; // Extent padding default
  static readonly GARDSBRUKSNUMMER = 'GARDSBRUKSNUMMER';
  static readonly GARDSKART_PREFIX = 'GARDSKART_';
  static readonly KML_DEFAULT_OPTIONS = {
    crossOrigin: isDevMode() ? null : 'anonymous', // https://github.com/openlayers/openlayers/issues/12437#issuecomment-867635120
    defaultStyle: [OpenlayersHelper.kmlDefaultStyle()],
    extractStyles: true,
    iconUrlFunction: href => OpenlayersHelper.fixRelativeImageUrlsInKml(href),
  } as KmlOptions;
  static readonly ORTHO = 'flybilder';
  static readonly PROPERTY_COLOR = 'rgba(255, 0, 0, 0.8)';
  static readonly TRANSPARENT = 'rgba(0, 0, 0, 0)';
  static readonly ZOOMTODEFAULT = MapConfig.EIENDOMSGRENSE;
}
