import { inject, Injectable } from '@angular/core';
import { ApiConfig } from '@config/api.config';
import { AppConfig } from '@config/app.config';
import { MapConfig } from '@config/map.config';
import { JsonHelper } from '@helpers/json.helper';
import { OpenlayersHelper } from '@helpers/openlayers.helper';
import { OverlayAdd } from '@models/overlay-add';
import { UpdatedPropertiesInterface } from '@models/property/property-state';
import { BackgroundMapsService } from '@services/background-maps.service';
import { DrawService } from '@services/draw.service';
import { MapCanvasService } from '@services/map-canvas.service';
import { MapTools } from '@tools/map.tools';
import {
  gardskartortostyle,
  gardskartotherstyle,
  gardskartstyle,
  propertyStyleAll,
  propertyStyleAll_notFarm,
  propertyStyleDefault,
  propertyStyleDefault_notFarm,
  textStyleHideUndecided,
} from '@tools/mapStyles';
import { MiscTools } from '@tools/misc.tools';
import { defaults as cDefaults } from 'ol/control';
import { buffer, Extent, intersects } from 'ol/extent';
import Feature from 'ol/Feature';
import { GeoJSON, GPX, KML } from 'ol/format';
import { Geometry, Polygon, SimpleGeometry } from 'ol/geom';
import { DragAndDrop, defaults as iDefaults } from 'ol/interaction';
import Interaction from 'ol/interaction/Interaction';
import ImageLayer from 'ol/layer/Image';
import Image from 'ol/layer/Image';
import Layer from 'ol/layer/Layer';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import { unByKey } from 'ol/Observable';
import Overlay from 'ol/Overlay';
import { get as getProj, transform } from 'ol/proj';
import ImageSource from 'ol/source/Image';
import ImageWMS from 'ol/source/ImageWMS';
import VectorSource from 'ol/source/Vector';
import Fill from 'ol/style/Fill';
import Icon, { Options as IconOptions } from 'ol/style/Icon';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text, { Options as TextOptions } from 'ol/style/Text';
import View from 'ol/View';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { LegendService } from 'src/app/core/services/legend.service';
import { PropertyService } from 'src/app/core/services/property.service';

const DRAW_ID = 'drawVectorLayer';

@Injectable()
export class MapService {
  #map: Map;
  private backgroundMapsService = inject(BackgroundMapsService);
  private drawnFeatures = new BehaviorSubject<boolean>(false);
  private drawnLayerData = new Subject<{ disable: boolean; name: string }>();
  private drawService = inject(DrawService);
  private dsNumber = 0;
  private lastZoomedLayer: Layer;
  private legendService = inject(LegendService);
  private mapCanvas = inject(MapCanvasService);
  private mapDrawChange: any;
  private mapMoveKey: any;
  private moveMapObservable = new Subject<boolean>();
  private overlaysToAdd = new BehaviorSubject<OverlayAdd>(null);
  private prematureLayers = [];
  private propertyService = inject(PropertyService);
  private propertyStyle = {
    all: (f: Feature) => {
      if (this.featureIsVisible(f)) {
        return propertyStyleAll(f);
      } else {
        return new Style();
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    all_notFarm: (f: Feature) => {
      if (this.featureIsVisible(f)) {
        return propertyStyleAll_notFarm(f);
      } else {
        return new Style();
      }
    },
    default: (f: Feature) => {
      if (this.featureIsVisible(f)) {
        return propertyStyleDefault(f);
      } else {
        return new Style();
      }
    },
    notFarm: (f: Feature) => {
      if (this.featureIsVisible(f)) {
        return propertyStyleDefault_notFarm(f);
      } else {
        return new Style();
      }
    },
  };
  private sessionDeleteBlock = false;
  private sessionSaveBlock = false;
  private srid = 25832;
  dragAndDropInteraction = new DragAndDrop({
    formatConstructors: [new GPX({}), GeoJSON, new KML(MapConfig.KML_DEFAULT_OPTIONS)],
  });
  gkStyle = {
    default: (f: Feature, r: number) => {
      if (this.featureIsVisible(f)) {
        return gardskartstyle(f, r);
      } else {
        return new Style();
      }
    },
    orthophoto: (f: Feature, r: number) => {
      if (this.featureIsVisible(f)) {
        return gardskartortostyle(f, r);
      } else {
        return new Style();
      }
    },
    other: (f: Feature, r: number) => {
      if (this.featureIsVisible(f)) {
        return gardskartotherstyle(f, r);
      } else {
        return new Style();
      }
    },
  };

  get hasDrawnFeatures$(): Observable<boolean> {
    return this.drawnFeatures.asObservable();
  }

  get hasDrawnLayerData$(): Observable<{ disable: boolean; name: string }> {
    return this.drawnLayerData.asObservable();
  }

  get map() {
    if (!this.#map) {
      this.initMap(AppConfig.DEFAULT_SRID);
    }

    return this.#map;
  }

  get overlaysToAddToMap$(): Observable<OverlayAdd> {
    return this.overlaysToAdd.asObservable();
  }

  // clear every but the first n map layers
  private clearAllExceptFirst(n: number): void {
    const last = this.map.getLayers().getLength();
    for (let i = last; i > n; i--) {
      this.map.getLayers().removeAt(i - 1);
    }
    return;
  }

  /**
   * Will disable all gardskartLayers in the map. Gardskartlayers are all layers
   * starting with MapConfig.gardskartPrefix.
   * @return {boolean} true if any gardskartlayers arefound and removed, false otherwise
   */
  private disableGardskartLayers(): boolean {
    if (this.map === undefined) {
      return false;
    }
    let retVal = false;
    this.map.getLayers().forEach(function (lyr) {
      if (MapTools.isGardskartLayer(lyr)) {
        lyr.setVisible(false);
        retVal = true;
      }
    });
    return retVal;
  }

  /**
   * Will disable all gardskartLayers in the map. Gardskartlayers are all layers
   * starting with MapConfig.gardskartPrefix.
   * @return {boolean} true if any gardskartlayers arefound and removed, false otherwise
   */
  private disableGardskartLayersExcept(type: string): boolean {
    if (this.map === undefined) {
      return false;
    }
    let retVal = false;
    this.map.getLayers().forEach(function (lyr) {
      if (MapTools.isGardskartLayer(lyr)) {
        if (lyr.get('id').indexOf(type) !== -1) {
          return;
        }
        lyr.setVisible(false);
        retVal = true;
      }
    });
    return retVal;
  }

  private featureIsVisible(feature): boolean {
    const spid = MiscTools.subpropertyIdFromFeature(feature);
    const belongingEiendom = this.propertyService.getGrunneiendom(spid);
    return belongingEiendom ? belongingEiendom.isVisible : true;
  }

  private getBorderLayers() {
    return this.getLayers(MapConfig.EIENDOMSGRENSE);
  }

  /**
   * Get existing layer opacity
   * @param {string} gkType gardskart type
   * @param {number} configOpacity configured opacity
   */
  private getExistingOpacity(gkType: string, configOpacity: number): number {
    const mainPropertyLayer = this.getLayer('GARDSKART_' + gkType);
    return mainPropertyLayer ? mainPropertyLayer.getOpacity() : configOpacity;
  }

  private getFeaturesForLayer(layerid) {
    const layer = this.getLayerById(layerid);
    if (!layer) {
      return null;
    }

    return layer.getSource().getFeatures();
  }

  /**
   * Remove overlay from map
   * @param  {string}  id   id of layer
   */
  private removeOverlay(overlayId: string): void {
    const overlay = this.map.getOverlayById(overlayId);
    if (overlay) {
      this.map.removeOverlay(overlay);
    }
  }

  private setOverlaysVisible(layer, visible, prefix = '') {
    const source = layer.getSource();
    if (!source || typeof source.getFeatures !== 'function') {
      return;
    }
    const features = source.getFeatures();

    features.forEach(f => {
      const overlayId = `${prefix.replace(/\s/g, '') + 'draw'}${f.getId()}`;
      const overlay = this.map.getOverlayById(overlayId);
      if (overlay) {
        overlay.getElement().setAttribute('style', `display: ${visible ? 'block' : 'none'}`);
      }
    });
  }

  // Stop listening to drawLayer change event
  private stopDrawChanged() {
    unByKey(this.mapDrawChange);
  }

  // Update the canvas with the features in draw layer.
  private updateCanvas(features: Feature<Geometry>[]) {
    if (features.length > 0) {
      const json = new KML(MapConfig.KML_DEFAULT_OPTIONS).writeFeatures(this.prepareFeaturesForSave(features), {
        featureProjection: getProj('EPSG:' + this.getSRID()),
      });

      this.mapCanvas.postCanvas(json);
      this.drawnFeatures.next(true);
    } else {
      this.mapCanvas.setEmptyCanvas();
      this.drawnFeatures.next(false);
    }
  }

  // belonging to propertyId (Note: not GARDSKART, done in gardskart-map component)
  private visiblePropertyLayers(propertyId: string, b: boolean) {
    const map = this.map;
    const mapLayers = map.getLayers();
    for (let i = 0, len = mapLayers.getArray().length; i < len; i++) {
      const layer = mapLayers.getArray()[i];
      const id = layer.get('id');
      if (id.indexOf(MapConfig.GARDSKART_PREFIX) !== -1) {
        continue;
      }
      if (id.indexOf(propertyId) > -1) {
        layer.setVisible(b);
        // layer.setStyle( propertyStyleDefault ); // does not exist on base layers
      }
    }
    return;
  }

  /**
   * Add background layers to the map
   * @param {Array<any>} layers layers to add
   */
  addBackgroundLayers(layers: any[]): void {
    for (const layer of layers) {
      this.map.addLayer(layer);
    }
  }

  /**
   * Add change event
   * @param {any} eventLayer eventLayer
   */
  addChangeLegendHandler(eventLayer: any): void {
    const localThis = this;
    if (eventLayer.getProperties().type !== 'base') {
      // Add event to layers with legend
      eventLayer.on('change:visible', e => {
        localThis.legendService.updateLegends(e.target, e.oldValue);
      });
    }
  }

  /**
   * Add map interaction
   * @param {Interaction} interaction interaction to add
   */
  addInteraction(interaction: Interaction): void {
    this.map.addInteraction(interaction);
  }

  /**
   * Add layer to map
   * @param  {string}  id         id of layer
   * @param  {any}     inputLayer layer to add
   * @return {boolean}            true if operation was successful
   */
  addLayer(id: string, inputLayer: any): boolean {
    const pos = this.getNumberOfLayers();
    return this.addLayerAt(id, inputLayer, pos);
  }

  /**
   * Add a layer at a certain position
   * @param {string} id id for layer
   * @param {any} inputLayer layer
   * @param {number} pos position
   */
  addLayerAt(id: string, inputLayer: any, pos: number): boolean {
    // Check that id is not represented already
    if (typeof id === 'undefined' || typeof inputLayer === 'undefined') {
      return false;
    }

    // Add to map if map is created
    if (this.map !== null && typeof this.map !== 'undefined') {
      inputLayer.set('id', id);
      if (!MapTools.isGardskartLayer(inputLayer) && MapTools.hasLegend(inputLayer)) {
        this.addChangeLegendHandler(inputLayer);
      }
      this.map.getLayers().insertAt(pos, inputLayer);
    }

    return true;
  }

  /**
   * [addLayerToMapPremature description]
   * @param  {string}  id         An string identifier for this layer
   * @param  {any}     inputLayer The layer to add
   * @return {boolean}            True on success, false otherwise
   */
  addLayerToMapPremature(id: string, inputLayer: any, pos = -1): boolean {
    // Check that id is not represented already
    if (typeof id === 'undefined' || typeof inputLayer === 'undefined') {
      return false;
    }

    if (this.prematureLayers !== null) {
      for (let i = 0; i < this.prematureLayers.length; i++) {
        const item = this.prematureLayers[i].layer;
        if (item.get('id') === id) {
          // replace layer
          this.prematureLayers[i] = { layer: inputLayer, position: pos };
          inputLayer.set('id', id);
          return true;
        }
      }
      inputLayer.set('id', id);
      this.prematureLayers.push({ layer: inputLayer, position: pos });
      return true;
    }
    return false;
  }

  /**
   * Add  all the layers in the list of premature layers to the map.
   * Premature layers list is set to null afterwards
   */
  addPremature(): void {
    // Check that id is not represented already
    if (this.prematureLayers !== null) {
      for (const item of this.prematureLayers) {
        const layer = item.layer;
        const pos = item.position;
        if (pos > -1) {
          this.addLayerAt(layer.get('id'), layer, pos);
        } else {
          this.addLayer(layer.get('id'), layer);
        }
      }
    }
    this.prematureLayers = null;
    return;
  }

  addPrematureOrLayer(id: string, inputLayer: any): boolean {
    if (this.map === undefined || this.map.getLayers().getLength() === 0) {
      return this.addLayerToMapPremature(id, inputLayer);
    } else {
      return this.addLayer(id, inputLayer);
    }
  }

  addPrematureOrLayerAt(id: string, l: any, pos: number): boolean {
    if (this.map === undefined || this.map.getLayers().getLength() === 0) {
      return this.addLayerToMapPremature(id, l, pos - 1);
    } else {
      return this.addLayerAt(id, l, pos);
    }
  }

  /**
   * Cleanup observable and empty canvas when moving out of map component
   * We are use RouteReuse for Min Side so that is not affected.
   */
  cleanOverlayObservable() {
    this.overlaysToAdd.next(null);
    this.mapCanvas.setEmptyCanvas();
    this.drawnFeatures.next(false);
  }

  /**
   * Copy features from a drawing to drawLayer. Remove existing features in drawLayer.
   * @param {string} drawingId the id of the drawing to put into drawLayer
   */
  copyDrawingToCanvas(drawingId: string): boolean {
    // If we don't have drawLayer yet, initialize it.
    const drawLayer = this.initDrawingLayer();
    if (!drawLayer) {
      return false;
    }

    const drawingLayer = this.getLayer(drawingId);
    if (!drawingLayer) {
      return false;
    }

    // Cleanup drawLayer first.
    this.removeDrawOverlays(DRAW_ID);
    this.removeFeatures(DRAW_ID);

    // Get all the features in the drawing.
    const features = (drawingLayer as VectorLayer<VectorSource>).getSource().getFeatures();

    // Clone the features so we don't edit the same object.
    const newFeatures = features.map(f => {
      const clone = f.clone();
      clone.setId(f.getId());
      return clone;
    });

    // Put in all the features from the drawing into drawLayer.
    drawLayer.getSource().addFeatures(newFeatures);

    // Need to rebuild the features to get the overlays working.
    newFeatures.forEach(f => this.rebuildFeature(f));

    // Update the drawn features counter so we can add more without them disappearing.
    this.drawService.updateDrawNumber(newFeatures.length);

    // Now that we have already added existing (saved) features, start listening for map events: add/remove feature.
    this.initFeatureChangeListeners(drawLayer);

    return true;
  }

  /**
   * Create a datatype drawing layer to show a saved drawn layer
   * @param {number} id id of drawing
   * @param {string} name name of drawing
   * @param {KML} featureCollection KML with features
   */
  createDrawingLayer(id, name, featureCollection) {
    const kml = new KML(MapConfig.KML_DEFAULT_OPTIONS);
    const projection = getProj('EPSG:' + this.getSRID());
    const features: Feature<Geometry>[] = kml.readFeatures(featureCollection, {
      featureProjection: projection,
    });

    if (features.length === 0) {
      // Could not create features of a drawing. This is an error.
      return false;
    }

    features.forEach(f => this.rebuildFeature(f, id + '_'));

    const iconStyle = new Style({
      image: new Icon({
        anchor: [0.5, 46],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        scale: 0.5,
        src: 'img/maki/red_circle-24@2x.png',
      } as IconOptions),
    });

    const drawSource = new VectorSource({
      wrapX: false,
    });

    const drawVector = new VectorLayer({
      savedDrawing: true,
      source: drawSource,
      style: iconStyle,
    } as any);
    drawVector.set('id', id, true);
    drawVector.set('legend', 'no', true);
    drawVector.set('name', name, true);
    drawVector.setZIndex(5);

    drawSource.addFeatures(features);

    this.map.addLayer(drawVector);

    return true;
  }

  /**
   * Create overlay for feature
   * @param {HTMLElement} element the overlay html element
   * @param {number} id id of overlay
   * @param {string} text text of the html element
   * @param {number[]} coordinates coordinates of the overlay
   * @param {boolean} show determines if the overlay should be shown as default
   */
  createFeatureOverlay(element, id, text, coordinates, show) {
    if (this.map) {
      const overlay = new Overlay({
        element: element,
        id: id,
        offset: [0, -15],
        positioning: 'bottom-center',
      });

      overlay.setPosition(coordinates);
      overlay.getElement().className = 'tooltip-readded tooltip-static-readded ' + 'obj' + id.substr(4);
      overlay.setOffset([0, -7]);
      this.map.addOverlay(overlay);
      element.innerText = text;

      if (show) {
        overlay.getElement().parentElement.removeAttribute('hidden');
      } else {
        overlay.getElement().parentElement.setAttribute('hidden', 'true');
      }
    }
  }

  /**
   * @param  {string} geojson features
   * @param  {any}   an array if objects defining colormap for each featuretype.
   *                 Each objectidentified by id property
   * @return {layerVector} A layer with style correspondign to the colormap
   */
  createGardskartLayer(
    features: string,
    colorMap: any,
    dataProj: string,
    mapProj: string,
    gkInStyle: string,
  ): VectorLayer<VectorSource> {
    // Need to preserve this in local variable due to interaction with openlayers via
    // stylefunction
    if (typeof features === 'undefined' || typeof colorMap === 'undefined') {
      return null;
    }
    let gardskartLayer = null;
    const format = new GeoJSON({
      dataProjection: dataProj,
      featureProjection: mapProj,
    });
    const propertyFeatures = format.readFeatures(features);
    if (propertyFeatures[0].getGeometry() === null) {
      return null;
    }

    for (const item of propertyFeatures) {
      item.set('show', false, true);
      const type = item.get('kode');
      const typeConfig = colorMap.configurations.find(item => item.configId === type);
      // Set color property in the feature. Used by style function
      if (typeConfig) {
        item.set('color', typeConfig.rgba);
        item.set('img', typeConfig.img);
      }
    }

    const propertySource = new VectorSource({
      features: propertyFeatures,
    });
    gardskartLayer = new VectorLayer({
      opacity: colorMap.opacity,
      source: propertySource,
    });

    gardskartLayer.setStyle(this.gkStyle[gkInStyle]);

    return gardskartLayer;
  }

  /**
   * creat a WMS layer given a json structure
   * @param  config configuration as json structure
   * @return        openlayers WMS layer
   */
  createWmsLayer(config: any): ImageLayer<ImageSource> | undefined {
    if (config.options && config.id) {
      const olConfig = config.options;
      olConfig.id = config.id;
      // Prepare the source-object:
      if (!config.source) {
        return undefined;
      }
      olConfig.source = new ImageWMS(config.source);
      const wmsLayer = new Image(olConfig);
      if (config.ui_options) {
        wmsLayer.set('name', config.ui_options.name);
        if (config.ui_options.legend) {
          wmsLayer.set('legend', config.ui_options.legend);
        }

        const minScale = config.ui_options.minScale;
        if (minScale !== undefined) {
          // this.layer.setMaxResolution(200);
          wmsLayer.setMaxResolution(OpenlayersHelper.getResolution(minScale));
        }
        const maxScale = config.ui_options.maxScale;
        if (maxScale !== undefined) {
          wmsLayer.setMinResolution(20);
        }
      }
      return wmsLayer;
    }
    return undefined;
  }

  /**
   * Deletes a drawing layer from the map
   *  @param {number} id id of layer
   */
  deleteDrawingLayer(id) {
    const layer = this.getLayer(id);
    if (layer) {
      this.removeDrawOverlays(id);
      this.removeFeatures(id);
      this.removeLayer(id);
    }
  }

  /**
   * Flush session data. Ensure that we have persisted all drawings prior to logging in.
   */
  flushSessionData() {
    return new Promise<void>((resolve, reject) => {
      // If we don't have a map, there is nothing to flush.
      if (this.map === undefined) {
        resolve();
      }

      // If the draw layer has not been created, there is nothing to flush.
      const drawLayer = this.getLayer(DRAW_ID);
      if (!drawLayer) {
        return resolve();
      }

      // If there is nothing awaiting a debounce, there is nothing to flush.
      if (this.mapCanvas.debounceValue === undefined || this.mapCanvas.debounceValue === null) {
        return resolve();
      }

      // Post all drawn features to session data
      const json = new KML(MapConfig.KML_DEFAULT_OPTIONS).writeFeatures(
        this.prepareFeaturesForSave((drawLayer as VectorLayer<VectorSource>).getSource().getFeatures()),
        {
          featureProjection: getProj('EPSG:' + this.getSRID()),
        },
      );

      this.mapCanvas
        .flushCanvas(json)
        .then(() => resolve())
        .catch(error => reject(error));
    });
  }

  // Generate canvas data for save
  generateCanvasData() {
    const layer = this.getLayer(DRAW_ID);
    if (!layer || !(layer instanceof VectorLayer)) {
      return '';
    }

    return new KML(MapConfig.KML_DEFAULT_OPTIONS).writeFeatures(
      this.prepareFeaturesForSave((layer as VectorLayer<VectorSource>).getSource().getFeatures()),
      {
        featureProjection: getProj('EPSG:' + this.getSRID()),
      },
    );
  }

  /**
   * Fill map with content
   * @return {Observable<any>} of bgService
   */
  getBackgroundLayers(): Observable<any> {
    return this.backgroundMapsService.getBgGroup(this.srid).pipe(
      map(backgroundGroup => {
        const selection = this.backgroundMapsService.getSelected();
        const layers = [];
        for (const layer of backgroundGroup) {
          const id = layer.getProperties()['id'];
          if (id === selection) {
            layer.setVisible(true);
          } else {
            layer.setVisible(false);
          }

          layers.push(layer);
        }
        return layers;
      }),
    );
  }

  /**
   * Compute the zoom and center point for the map,
   * and convert it to be center and zoom that can be used to find
   * the same place in Kilden
   *
   */
  getCenterAndZoomForKilden(): any {
    const view = this.map.getView();
    const centre = view.getCenter();
    const projection = view.getProjection();
    const kildenZoom = view.getZoom() - 5; // -5 corresponds to kilden zoom, more or less

    const kildenCentre = transform(centre, projection, 'EPSG:25833');
    return {
      centre: kildenCentre,
      zoom: kildenZoom,
    };
  }

  // Get current UTM code
  getCode(): string {
    return this.map.getView().getProjection().getCode();
  }

  getDsNumber(): number {
    return this.dsNumber;
  }

  /**
   * looks through all layers in map, returing a list of all layers that is gardskar-layers.
   *
   * @return {Array<any>} A list of gardskart-layers
   */
  getGardskartLayers() {
    const layers = [];
    if (this.map) {
      this.map.getLayers().forEach(layer => {
        if (MapTools.isGardskartLayer(layer)) {
          layers.push(layer);
        }
      });
    }
    return layers;
  }

  /**
   * Get layer from map
   * @param  {string} id  id of layer to fetch from map
   * @return {any}        reference to layer
   */
  getLayer(id: string): any {
    if (this.map !== undefined) {
      let idx = 0;
      for (const layer of this.map.getLayers().getArray()) {
        if (layer.get('id') === id) {
          layer.set('index', idx);
          if (layer instanceof VectorLayer) {
            return layer as VectorLayer<VectorSource>;
          } else {
            return layer as ImageLayer<ImageWMS>;
          }
        }
        ++idx;
      }
    }
    return null;
  }

  /**
   * find layer by given id
   * @param  id of the layer
   * @return layer object or null
   */
  getLayerById(id: string) {
    let layer = null;
    this.map.getLayers().forEach(l => {
      if (l.get('id') === id) {
        layer = l;
      }
    });
    return layer;
  }

  getLayerFeaturesDownload(layerid) {
    // Reapply style to features to avoid google map pin.
    const saveFeatures = this.prepareFeaturesForSave(this.getFeaturesForLayer(layerid));
    // saveFeatures.forEach(f => this.rebuildFeature(f, layerid + '_', false));

    const json = new KML(MapConfig.KML_DEFAULT_OPTIONS).writeFeatures(saveFeatures, {
      featureProjection: getProj('EPSG:' + this.getSRID()),
    });

    // Add in icon links to main site's icons so the KML can be used elsewhere.
    return OpenlayersHelper.prefixRelativeImageUrls(json, ApiConfig.imageUrl + '/img');
  }

  /**
   * find all the layers containing IsSubstring in layerId
   * @param  idSubstring substring to search for in
   * @return             array of layers matching the substring
   */
  getLayers(idSubstring: string) {
    const layers = [];
    if (this.map) {
      this.map.getLayers().forEach(layer => {
        if (layer.get('id').indexOf(idSubstring) > -1) {
          layers.push(layer);
        }
      });
    }
    return layers;
  }

  /**
   * Return a reference to the map
   * @return {any}    reference to the map
   */
  getMap(): Map {
    return this.map;
  }

  /**
   * Returns the observable that updates on maps moveend
   * @return boolean observable
   */
  getMoveMapObservable(): Observable<boolean> {
    return this.moveMapObservable;
  }

  /**
   * Get the amount of layers in the current map
   * @return {number} Number of layers
   */
  getNumberOfLayers(): number {
    if (this.map === undefined) {
      return 0;
    }
    return this.map.getLayers().getArray().length;
  }

  getSRID(): number {
    return this.srid;
  }

  /**
   * Get extent of the current view
   * @return {Array<any>}   extent of current view
   */
  getViewExtent(): any[] {
    if (this.map === undefined) {
      return [];
    }
    return this.map.getView().calculateExtent(this.map.getSize());
  }

  /**
   * hideGardskartLayer is used for "Gårdskart uten tema"
   */
  hideGardskartLayers(): boolean {
    return this.disableGardskartLayers();
  }

  increaseDsNumber() {
    this.dsNumber += 1;
  }

  /**
   * Get extent of the current view
   * @return {VectorLayer<VectorSource>}   extent of current view
   */
  initDrawingLayer(disableLeftPanelEditing = true): undefined | VectorLayer<VectorSource> {
    if (this.map === undefined) {
      return undefined;
    }
    const existing = this.getLayer(DRAW_ID);
    if (existing) {
      return existing as VectorLayer<VectorSource>;
    }

    const iconStyle = new Style({
      image: new Icon({
        anchor: [0.5, 46],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        scale: 0.5,
        src: 'img/maki/red_circle-24@2x.png',
      } as IconOptions),
    });

    const drawSource = new VectorSource({ wrapX: false });

    const drawVector = new VectorLayer({
      source: drawSource,
      style: iconStyle,
    });
    drawVector.set('id', DRAW_ID, true);
    drawVector.set('legend', 'no', true);
    drawVector.set('name', 'Tegnede objekter', true);
    drawVector.setZIndex(9);

    this.drawnLayerData.next({ disable: disableLeftPanelEditing, name: drawVector.get('id') });
    this.map.addLayer(drawVector);

    return drawVector;
  }

  /**
   * Start the drawlayer with predefined features
   * @param {any} featureCollection features
   */
  initDrawingLayerWithExistingFeatures(featureCollection: any) {
    const drawLayer = this.initDrawingLayer(false);
    if (drawLayer) {
      const source = drawLayer.getSource();
      const projection = getProj('EPSG:' + this.getSRID());
      const features = new KML(MapConfig.KML_DEFAULT_OPTIONS).readFeatures(featureCollection, {
        featureProjection: projection,
      });
      features.forEach(f => this.rebuildFeature(f));
      this.drawService.updateDrawNumber(features.length);
      source.addFeatures(features);

      // Now that we have already added existing (saved) features, start listening for map events: add/remove feature.
      this.initFeatureChangeListeners(drawLayer);
    }
  }

  // Start listening to drawLayer change event
  initFeatureChangeListeners(layer: VectorLayer<VectorSource>) {
    const source = layer.getSource();

    // Fire a single initial updateCanvas call to notify existing subscribers
    setTimeout(() => {
      this.updateCanvas(source.getFeatures());
    }, 50);

    source.on('addfeature', () => {
      this.sessionSaveBlock = true;
      const features = source.getFeatures();

      setTimeout(() => {
        this.updateCanvas(features);
        this.sessionSaveBlock = false;
      }, 50);
    });

    source.on('removefeature', () => {
      this.sessionSaveBlock = true;
      const features = source.getFeatures();

      setTimeout(() => {
        if (!this.sessionDeleteBlock) {
          this.updateCanvas(features);
          this.sessionSaveBlock = false;
        }
      }, 50);
    });

    source.on('changefeature', () => {
      const features = source.getFeatures();
      if (!this.sessionSaveBlock) {
        this.updateCanvas(features);
      }
    });
  }

  /**
   * Create an empty map
   * @return {Map} new empty map
   */
  initMap(srid: number): Map {
    this.srid = srid;
    this.#map = new Map({
      // has to be an array of layers
      controls: cDefaults({
        attributionOptions: {
          collapsible: false,
        },
        zoomOptions: {
          duration: MapConfig.DURATION_DEFAULT,
          zoomInLabel: ' ',
          zoomInTipLabel: 'Zoom inn',
          zoomOutLabel: ' ',
          zoomOutTipLabel: 'Zoom ut',
        },
      }),

      interactions: iDefaults().extend([this.dragAndDropInteraction]),

      layers: [],
      target: 'map',
      view: new View({
        center: [378604, 7226208],
        enableRotation: false,
        maxZoom: 20,
        minZoom: 6,
        projection: getProj('EPSG:' + srid.toString()),
        zoom: 6,
      }),
    });
    return this.map;
  }

  /**
   * Check is layer inside extent, have a buffer on -100m
   * @param {any} reference to layer
   * @return {Boolean}   true/false
   */
  isLayerInViewExtent(printBox): boolean {
    if (this.map === undefined) {
      return false;
    }
    const pBoxExtent = printBox.getSource().getExtent();
    const viewExtent = this.getViewExtent();
    return intersects(viewExtent, buffer(pBoxExtent, -100));
  }

  /**
   * Save styles and feature data on drawn features for session
   * @param {Feature<Geometry>[]} features drawlayer features
   */
  prepareFeaturesForSave(features: Feature<Geometry>[]) {
    const featuresCloned = [];
    features.forEach(ft => {
      const f = ft.clone();
      f.setId(ft.getId());
      const type = f.get('type');

      if (f.get('old_style')) {
        f.setStyle(f.get('old_style'));
      }
      f.unset('old_style');

      // Remove the default (empty) Image for any Feature of Text type, to avoid getting a generic Pin on ex/import.
      if (f.get('type').toLowerCase() === 'text' || f.getStyle() instanceof Text) {
        const textStyle = f.getStyle() as Style;
        textStyle.setImage(
          undefined,
          // new Icon({
          //   anchor: [1.1, 1.1],
          //   anchorOrigin: 'bottom-right',
          //   anchorXUnits: 'fraction',
          //   anchorYUnits: 'fraction',
          //   color: undefined,
          //   offset: [0, 0],
          //   opacity: 0,
          //   scale: 1,
          //   size: [1, 1],
          //   src: `${ApiConfig.imageUrl}/img/maki/gk_placeholder.png`,
          // } as IconOptions),
        );
        f.setStyle(textStyle);
        // console.log(`using transparent img for Text`, f.getId(), textStyle.getText().getText(), textStyle.getImage());
      }

      f.set('featureStyle', JSON.stringify(f.getStyle(), JsonHelper.stripZones), true);

      const overlayId = 'draw' + f.getId();
      const overlay = this.map.getOverlayById(overlayId);
      if (overlay) {
        const htmlElement = overlay.getElement();

        const geo = f.getGeometry();
        let coordinates = null;

        if (type === 'polygon') {
          const interiorPoint = (geo as Polygon).getInteriorPoint();
          coordinates = interiorPoint.getCoordinates();
        } else {
          coordinates = (geo as SimpleGeometry).getLastCoordinate();
        }

        f.set('hasOverlay', true);
        f.set('overlayText', htmlElement.innerText, true);
        f.set('overlayId', overlayId, true);
        f.set('overlayCoordinates', JSON.stringify(coordinates), true);
      }

      featuresCloned.push(f);
    });

    return featuresCloned;
  }

  // Print ids of mapLayers
  printMapLayerIds(): void {
    if (this.map === undefined) {
      console.log('map is undefined');
      return;
    }
    if (this.map.getLayers().getLength() === 0) {
      console.log('map has been cleared');
      return;
    }
    for (let i = 0, len = this.map.getLayers().getArray().length; i < len; i++) {
      const l = this.map.getLayers().getArray()[i];
      const id = l.get('id');
      let z = id === 'otherLayers' ? '1|3' : l.getZIndex();
      z = id.indexOf(MapConfig.EIENDOMSGRENSE) > -1 ? '4|5|6|7' : z;
      console.log(id, '(zIndex', z + ')');
    }
    return;
  }

  /**
   * rebuild a feature from saved KML
   * @param {Feature} feature the overlay html element
   * @param {string} id the id of the layer. Optional, used for saved drawings
   * @param rebuildOverlay
   */
  rebuildFeature(feature: Feature<Geometry>, id = '', rebuildOverlay = true) {
    const defaultColor = [0, 0, 0];
    const defaultStrokeWidth = 2;

    if (feature && feature.get('featureStyle')) {
      const json = JSON.parse(feature.get('featureStyle'));

      // Create a new instance of an actual OL Style and hydrate it from the parsed json.
      //  This will make sure we have a proper Style class instance with methods etc., instead of a crude shallow copy
      const parsed: Style = new Style({
        fill: !json['fill_'] ? undefined : new Fill({ color: json['fill_']?.['color_'] || defaultColor }),
        image: !json['image_']?.['iconImage_']?.['src_']
          ? undefined
          : new Icon({
              anchor: [0.5, 0.5],
              anchorOrigin: 'top-left',
              anchorXUnits: 'fraction',
              anchorYUnits: 'fraction',
              color: json['image_']?.['fill_']?.['color_'],
              offset: [0, 0],
              opacity: 1,
              scale: json['image_']?.['scale_'] || 1,
              size: json['image_']?.['iconImage_']?.['size_'] || [1, 1],
              src: json['image_']?.['iconImage_']?.['src_'],
            } as IconOptions),
        stroke: !json['stroke_']
          ? undefined
          : new Stroke({
              color: json['stroke_']?.['color_'] || defaultColor,
              width: json['stroke_']?.['width_'] || defaultStrokeWidth,
            }),
        text: !json['text_']
          ? undefined
          : new Text({
              fill: new Fill({ color: json['text_']?.['fill_']?.['color_'] || defaultColor }),
              font: '100 normal 16px helvetica',
              maxAngle: json['text']?.['maxAngle_'] || 0.7,
              placement: 'point',
              stroke: new Stroke({
                color: json['text_']?.['stroke_']?.['color_'] || defaultColor,
                width: json['text_']?.['stroke_']?.['width_'] || defaultStrokeWidth,
              }),
              text: json['text_']?.['text_'] || '',
            } as TextOptions),
      });

      // console.log(`parsed`, parsed);

      feature.setStyle(parsed);
      // feature.unset('featureStyle', true);
    }

    if (rebuildOverlay) {
      this.rebuildFeatureOverlay(feature, id);
    }
  }

  /**
   * rebuild a feature overlay from saved KML
   * @param {Feature} feature the overlay html element
   * @param {string} id the id of the layer. Optional, used for saved drawings
   */
  rebuildFeatureOverlay(feature, id) {
    if (feature && feature.get('hasOverlay')) {
      const geo = feature.getGeometry();
      let coordinates = null;
      const type = feature.get('type');

      if (type === 'polygon') {
        const interiorPoint = geo.getInteriorPoint();
        coordinates = interiorPoint.getCoordinates();
      } else {
        coordinates = geo.getLastCoordinate();
      }
      const overlayAddition = new OverlayAdd();
      overlayAddition.id = id.replace(/\s/g, '') + feature.get('overlayId');
      overlayAddition.text = feature.get('overlayText');
      overlayAddition.coordinates = coordinates;
      overlayAddition.showLabel = feature.get('hasLabel') === 'true';

      this.overlaysToAdd.next(overlayAddition);
    }
  }

  removeAddedPropertiesLayers(gkLayers: any): void {
    if (this.map === undefined) {
      return;
    }
    const map = this.map;
    const mapLayers = map.getLayers();
    const toBeRemoved = [];
    for (let i = 0, len = mapLayers.getArray().length; i < len; i++) {
      const layer = mapLayers.getArray()[i];
      const id = layer.get('id');
      if (id.indexOf(MapConfig.EIENDOMSGRENSE) > -1 && id.length > MapConfig.EIENDOMSGRENSE.length) {
        toBeRemoved.push(layer);
        continue;
      }
      if (id.indexOf(MapConfig.GARDSBRUKSNUMMER) > -1 && id.length > MapConfig.GARDSBRUKSNUMMER.length) {
        toBeRemoved.push(layer);
        continue;
      }
      if (id.indexOf(MapConfig.DRIFTSSENTER) > -1 && id.length > MapConfig.DRIFTSSENTER.length) {
        toBeRemoved.push(layer);
        continue;
      }
      gkLayers.forEach(type => {
        if (id.indexOf(MapConfig.GARDSKART_PREFIX + type.id + '_') > -1) {
          toBeRemoved.push(layer);
        }
      });
    }
    toBeRemoved.forEach(target => map.removeLayer(target));
    return;
  }

  // Will remove all layers in the map and tidy up the states kept in mapService
  removeAllLayers(): void {
    this.disableGardskartLayers();
    if (this.map) {
      this.map.getLayers().clear();
      // this.clearAllExceptFirst(5);
    }
    this.lastZoomedLayer = null;
    // initialize the prematureLayers structure again
    this.prematureLayers = [];
  }

  /**
   * Find drawoverlay id and call for removeOverlay from map
   * @param  {string}  id   id of layer
   */
  removeDrawOverlays(layerId: string): void {
    const layer = this.getLayerById(layerId);
    if (!layer) {
      return;
    }
    const features = layer.getSource().getFeatures();

    features.forEach(f => {
      const overlayId = `draw${f.getId()}`;
      const overlayIdSaved = `${layerId}_${overlayId}`;
      this.removeOverlay(overlayId);
      this.removeOverlay(overlayIdSaved);
    });
  }

  /**
   * Remove feature from layer
   * @param {object} layer layer
   * @param {Feature} feature feature
   */
  removeFeature(layer, feature) {
    if (layer && feature) {
      const source = layer.getSource();
      source.removeFeature(feature);
      if (source.getFeatures().length === 0 && layer.get('id') === 'drawVectorLayer') {
        this.mapCanvas.setEmptyCanvas();
      }
    }
  }

  /**
   * Removes features
   * @param  {string}  id   id of layer
   */
  removeFeatures(layerId: string): void {
    const layer = this.getLayerById(layerId);
    if (!layer) {
      return;
    }

    this.sessionDeleteBlock = true;

    layer.getSource().clear();

    if (layerId === DRAW_ID) {
      this.mapCanvas.setEmptyCanvas();
      this.drawnFeatures.next(false);
    }

    setTimeout(() => (this.sessionDeleteBlock = false), 500);
  }

  /**
   * Remove all features from layer
   * @param {object} layer layer
   * @param {int[]} ids array of feature id to remove
   */
  removeFeaturesById(layer, ids) {
    if (layer && ids) {
      const features = layer.getSource().getFeatures();
      ids.forEach(id => {
        const feature = features.find(f => f.getId() === id);
        this.removeFeature(layer, feature);
      });

      if (features.length === ids.length && layer.get('id') === 'drawVectorLayer') {
        this.mapCanvas.setEmptyCanvas();
      }
    }
  }

  /**
   * Find fileoverlay id and call for removeOverlay from map
   * @param  {string}  id   id of layer
   */
  removeFileOverlays(layerId: string): void {
    const layer = this.getLayerById(layerId);
    if (!layer) {
      return;
    }
    const features = layer.getSource().getFeatures();

    features.forEach(f => {
      const fileOverlayId = layerId.replace(/\s/g, '') + f.get('overlayId');
      this.removeOverlay(fileOverlayId);
    });
  }

  /**
   * Remove map interaction
   * @param {Interaction} interaction interaction to remove
   */
  removeInteraction(interaction: Interaction): void {
    if (this.map !== undefined) {
      // to run tests
      this.map.removeInteraction(interaction);
    }
  }

  // show/hide layers (EIENDOMSGRENSE, GARDSBRUKSNUMMER, DRIFTSSENTER)
  /**
   * Remove layer from map
   * @param  {string}  id   id of layer
   * @return {boolean}      true if operation was successful
   */
  removeLayer(id: string): boolean {
    if (typeof id === 'undefined' || this.map === undefined) {
      return false;
    }
    let remove = null;
    this.map.getLayers().forEach(function (lyr) {
      if (id === lyr.get('id')) {
        remove = lyr;
      }
    });

    if (remove) {
      this.map.removeLayer(remove);
      return true;
    }

    return false;
  }

  removePropertyLayers(propertyId: string) {
    if (this.map === undefined) {
      return;
    }
    const map = this.map;
    const mapLayers = map.getLayers();
    const toBeRemoved = [];
    for (let i = 0, len = mapLayers.getArray().length; i < len; i++) {
      const layer = mapLayers.getArray()[i];
      if (layer.get('id').indexOf(propertyId) > -1) {
        toBeRemoved.push(layer);
        if (layer.get('id').indexOf('DRIFTSSENTER') > -1) {
          this.dsNumber -= 1;
        }
      }
    }
    toBeRemoved.forEach(target => map.removeLayer(target));
    return;
  }

  resetDsNumber(firstPropertyHasDs: boolean) {
    this.dsNumber = firstPropertyHasDs ? 1 : 0;
  }

  /**
   * Zoom to last zoomed layer
   */
  reZoomToLast(): void {
    // this time with right map size
    if (this.lastZoomedLayer === undefined || this.lastZoomedLayer === null) {
      return;
    }
    this.zoomToLayer(this.lastZoomedLayer, { duration: 0 });
  }

  /**
   * Set draw layer overlay visibility
   * @param {string}  layerId   layer to modify
   * @param {boolean} bool      visibility value
   */
  setDrawOverlayVisible(layerId: string, bool: boolean): void {
    const layer = this.getLayerById(layerId);
    if (!layer) {
      return;
    }
    this.setOverlaysVisible(layer, bool);
  }

  /**
   * Replaces the existing map
   * @param {Map} map Value to set
   */
  setMap(map: Map): void {
    this.#map = map;
  }

  /**
   * Set layer opacity
   * @param
   */
  setOpacity(layerId: string, opacity: number): void {
    if (this.map === undefined) {
      return;
    }
    this.map.getLayers().forEach(olLayer => {
      if (olLayer.get('id') === layerId) {
        olLayer.setOpacity(opacity);
      }
    });
  }

  /**
   * Set layer visibility
   * @param {string}  layerId   layer to modify
   * @param {boolean} bool      visibility value
   */
  setVisible(layerId: string, bool: boolean, overlayPrefix = ''): void {
    if (this.map === undefined) {
      return;
    }
    this.map.getLayers().forEach(olLayer => {
      if (olLayer.get('id') === layerId) {
        this.setOverlaysVisible(olLayer, bool, overlayPrefix);
        olLayer.setVisible(bool);
      }
    });
  }

  /**
   *  Function that toggles style on eienedomsgrense layer
   *  @param checked {boolean} whether 'Vis alle' is selected or not
   *  @param isFarm {boolean}
   */
  showAllBorders(checked: boolean, isFarm: boolean) {
    this.getBorderLayers().forEach(borderLayer => {
      if (borderLayer !== null) {
        if (checked) {
          if (isFarm) {
            borderLayer.setStyle(this.propertyStyle.all);
          } else {
            borderLayer.setStyle(this.propertyStyle.all_notFarm);
          }
        } else {
          if (isFarm) {
            borderLayer.setStyle(this.propertyStyle.default);
          } else {
            borderLayer.setStyle(this.propertyStyle.notFarm);
          }
        }
      }
    });
  }

  showAllTeiger(showAll: boolean) {
    this.getLayers(MapConfig.GARDSBRUKSNUMMER).forEach(layer => {
      layer.setStyle((feature: Feature<Geometry>, resolution) => textStyleHideUndecided(feature, resolution, showAll));
    });
  }

  /**
   * Function will add the specified layer to mapService with the id
   * MapConfig.GARDSKART_PREFIX + type ie. GARDSKART_ar5kl7
   * MapService should only have one gardskart layer at any time
   * @param {layer.Layer} layer to add
   * @param {string}  type        type of gardskart
   */
  showGardskartLayer(layerInput: Layer, type: string, gardskartId: string): void {
    this.disableGardskartLayersExcept(type);
    layerInput.set('id', gardskartId, true);
    layerInput.set('legend', 'yes', true);
    // bg: 0, nibio-wms: 1, gk: 2, other-wms: 3, grenser: 4,5,6,7, gbnr/ds: 8, draw: 9, print: 10
    layerInput.setZIndex(2);
    layerInput.setOpacity(this.getExistingOpacity(type, layerInput.getOpacity()));
    this.removeLayer(gardskartId);
    // const boundaryLayer = this.getLayer(MapConfig.EIENDOMSGRENSE + suffix);
    // const here = boundaryLayer.get('index');
    const ingen = this.getLayer('ingen');
    const here = ingen ? ingen.get('index') + 1 : 1; // after empty layer
    this.addPrematureOrLayerAt(gardskartId, layerInput, here);
    // this.printMapLayerIds();
  }

  // Start listen to the maps movend and update observable
  startMapChanged() {
    this.mapMoveKey = this.map.on('moveend', () => {
      this.moveMapObservable.next(true);
    });
  }

  // Stop listen to the maps moveend
  stopMapChanged() {
    unByKey(this.mapMoveKey);
  }

  togglePropertyLayers(updatedProperties: UpdatedPropertiesInterface) {
    if (this.map === undefined) {
      return;
    }
    if (updatedProperties.property === undefined) {
      return;
    }
    const happened = updatedProperties.type;
    const propertyId = updatedProperties.property.toString();
    if (happened === 'SHOW_PROPERTY') {
      this.visiblePropertyLayers(propertyId, true);
    }
    if (happened === 'HIDE_PROPERTY') {
      this.visiblePropertyLayers(propertyId, false);
    }
    return;
  }

  /**
   * Zoom in one level in map
   */
  zoomIn(): void {
    if (this.map === undefined) {
      return;
    }
    const current = this.map.getView().getZoom();
    this.map.getView().animate({
      zoom: current + 1,
    });
  }

  /**
   * Zoom out one level in map
   */
  zoomOut(): void {
    if (this.map === undefined) {
      return;
    }
    const current = this.map.getView().getZoom();
    this.map.getView().animate({
      zoom: current - 1,
    });
  }

  /**
   * Zoom to the extent that includes all visible property borders
   * @return false if something goes wrong
   */
  zoomToAll(): boolean {
    if (this.map === undefined) {
      return false;
    }
    let globalExtent: Extent;
    let i = 0;
    this.map.getLayers().forEach(function (lyr: VectorLayer<VectorSource>) {
      if (lyr.get('id').indexOf(MapConfig.EIENDOMSGRENSE) > -1) {
        // wrap in try/catch to avoid code crash if layer does not support the
        // functions called on it
        try {
          // if (!lyr.get('visible')) { return; } // interrupt function, continue foreach
          const extent = lyr.getSource().getExtent();
          if (i === 0) {
            globalExtent = extent;
          } else {
            const x_min = Math.min(globalExtent[0], extent[0]);
            const x_max = Math.max(globalExtent[2], extent[2]);
            const y_min = Math.min(globalExtent[1], extent[1]);
            const y_max = Math.max(globalExtent[3], extent[3]);
            globalExtent = [x_min, y_min, x_max, y_max];
          }
          i++;
        } catch {
          return;
        }
      }
    });
    this.map.getView().fit(globalExtent, {
      duration: MapConfig.DURATION_DEFAULT,
      padding: MapConfig.EXTENT_PADDING,
      size: this.map.getSize(),
    });
    return true;
  }

  /**
   * Zooming to the extent of the layer defined in  MapConfig.ZOOMTODEFAULT
   */
  zoomToDefault() {
    this.zoomToLayerId(MapConfig.ZOOMTODEFAULT);
  }

  /**
   * zoom to the extent  passed as parameter
   * @param  extent Extent
   * @return        void
   */
  zoomToExtent(extent: Extent) {
    if (!extent) {
      return;
    }
    this.map.getView().fit(extent, {
      duration: MapConfig.DURATION_DEFAULT,
      padding: MapConfig.EXTENT_PADDING,
      size: this.map.getSize(),
    });
  }

  /**
   * Zoom to (focus on) specified layer
   * @param {any} layer   layer to zoom to
   * @param options
   */
  zoomToLayer(layer: any, options?: { duration?: number }): void {
    const extent = layer.getSource().getExtent();

    if (this.map === undefined) {
      return;
    }
    const view = this.map.getView();

    // Adding 'size' as FitOption since AngularSplit changes the map size after map init.
    // Was causing the initial zoom to default (aka Eiendomsgrense) not to take effect.
    // Docs: https://openlayers.org/en/latest/apidoc/module-ol_View-View.html#fit
    // Ref: https://stackoverflow.com/questions/60414071/how-to-fit-openlayers-map-when-map-is-not-full-size
    view.fit(extent, {
      duration: options?.duration || MapConfig.DURATION_DEFAULT,
      padding: MapConfig.EXTENT_PADDING,
      size: this.map.getSize(),
    });
    this.lastZoomedLayer = layer;
  }

  /**
   * Zoom to (focus on) specified layer
   * @param  {string}  id   id of layer to zoom to
   * @return {boolean}      whether or not the zoom was successful
   */
  zoomToLayerId(id: string): boolean {
    const map = this.map;
    if (typeof id === 'undefined' || map === undefined) {
      return false;
    }
    const mainThis = this;
    map.getLayers().forEach((lyr: VectorLayer<VectorSource>) => {
      if (id === lyr.get('id')) {
        // wrap in try/catch to avoid codecrach if layer does not support the
        // functions called on it
        try {
          const extent = lyr.getSource().getExtent();
          map.getView().fit(extent, {
            duration: MapConfig.DURATION_DEFAULT,
            padding: MapConfig.EXTENT_PADDING,
            size: map.getSize(),
          });
          mainThis.lastZoomedLayer = lyr;
          return true;
        } catch (e) {
          console.error(`zoomToLayerId trycatch err`, id, e);
          return false;
        }
      }
      return false;
    });
    return false;
  }
}
