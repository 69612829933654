import { NgClass } from '@angular/common';
import { Component, inject, Input, input, OnInit, viewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { StoreStates } from '@store/store-states';
import { ModalDirective, ModalModule } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

/*
  Example of usage:

  Data binding:
  <gk-modal #warningDialog [title]="modal.title" [content]="modal.content" [dialogMode]="modal.mode"></gk-modal>

  Without data binding:
  <gk-modal #warningDialog title="Sample title" content="Example content" dialogMode="warning"></gk-modal>

  With custom content:
  <gk-modal #signupDialog title="Sign up" dialogMode="info">
    <form>
      First name: <input type="text" name="fname"><br>
      Last name: <input type="text" name="lname"><br>
      <input type="submit" value="Sign up">
    </form>
  </gk-modal>

  Use #warningDialog (or other identifier, i.e. #<identifier-name>) to be able to call the components public functions. Like this:

  warningDialog.show();

  warningDialog.hide();
 */

@Component({
  imports: [ModalModule, NgClass],
  selector: 'gk-modal',
  styleUrls: ['./modal.component.scss'],
  templateUrl: './modal.component.html',
})

/**
 * ModalComponent:
 * Displays a dialog (modal) as overlay (centered on screen)
 *
 * @param  @Input {string}            'title'       Title of the modal (displayed in modal header).
 * @param  @Input {string}            'header'      Header of the modal (displayed in modal header). Overrides attr. title.
 * @param  @Input {string}            'content'     Content (text) of modal - dialog message.
 * @param  @Input {string} [optional] 'buttonText'  Text of 'dismiss' button. Default is 'OK'.
 * @param  @Input {string} [optional] 'dialogMode'  Mode of the dialog ('info', 'success', 'warning', 'danger', 'default').
 * @param  @Input {string} [optional] 'showIcon'    String-represented boolean. Decides if icon is displayed in modal header.
 * @param  @Input {string} [optional] 'customIcon'  Identifier for custom icon (i.e. 'glyphicon-home')
 * @param  @Input {string} [optional] 'hideFooter'  Hides the footer of the modal (if set to true)
 */
export class ModalComponent implements OnInit {
  private store = inject<Store<StoreStates>>(Store);

  readonly buttonText = input<string>('OK');
  readonly content = input<string>(undefined);
  readonly header = input<string>(undefined);
  readonly ignoreBackdropClick = input<boolean>(false);
  readonly modal = viewChild<ModalDirective>('modal');
  readonly showBackdrop = input<boolean>(true);
  readonly title = input<string>(undefined);
  @Input() customIcon? = '';
  @Input() dialogMode? = 'default';
  @Input() hideBody? = false;
  @Input() hideFooter? = false;
  @Input() showIcon? = true;

  dialogModes = {
    customInfo: {
      class: 'panel-heading-custom-success',
      icon: 'bi bi-info-circle',
    },
    danger: {
      class: 'panel-heading-custom-danger',
      icon: 'bi bi-exclamation-triangle',
    },
    default: {
      class: 'panel-default',
      icon: '',
    },
    info: {
      class: 'panel-info',
      icon: 'bi bi-info-circle',
    },
    pdf: {
      class: 'panel-heading-custom-success',
      icon: 'bi bi-patch-check',
    },
    success: {
      class: 'panel-heading-custom-success',
      icon: 'bi bi-check-square',
    },
    warning: {
      class: 'panel-heading-custom-warning',
      icon: 'bi bi-exclamation-triangle',
    },
  };
  modalActive: boolean;
  modalEvents = new Subject<string>();

  /**
   * Hide the modal
   */
  hide(): void {
    this.modal().hide();
    this.modalEvents.next('hide');
    // Hide is usually handled elsewhere, not through store.
    // this.store.dispatch({ type: STANDARD_STATE });
    setTimeout(() => {
      this.modalActive = false;
    }, 1000);
  }

  ngOnInit() {
    // set mode to default if input is invalid
    this.dialogMode = this.dialogMode.toLowerCase();
    if (!(this.dialogMode in this.dialogModes)) {
      this.dialogMode = 'default';
    }

    // convert to boolean value if input is given as string
    if (String(this.showIcon) === 'true') {
      this.showIcon = true;
    } else if (String(this.showIcon) === 'false') {
      this.showIcon = false;
    }

    // convert to boolean value if input is given as string
    if (String(this.hideFooter) === 'true') {
      this.hideFooter = true;
    } else if (String(this.hideFooter) === 'false') {
      this.hideFooter = false;
    }

    // convert to boolean value if input is given as string
    if (String(this.hideBody) === 'true') {
      this.hideBody = true;
    } else if (String(this.hideBody) === 'false') {
      this.hideBody = false;
    }
  }

  /**
   * Show the modal
   */
  show(): void {
    this.modalActive = true;
    const modal = this.modal();
    modal.config.backdrop = this.showBackdrop();
    if (this.showBackdrop() && this.ignoreBackdropClick()) {
      modal.config.ignoreBackdropClick = true;
    }
    modal.show();
    this.modalEvents.next('show');

    /**
     * Temp hack: Move the backdrop to the correct z-index stacking context.
     * We use this hack which moves the inserted <bs-modal-backdrop> from before </body> to inside the <gk-header>.
     * This puts the backdrop in the correct z-index stacking context so the backdrop can overlay both header and main
     * without overlaying the actual modal content.
     * Remnant from the Bootstrap v3 to v5 upgrade.
     */
    const bsBackdrops = document.getElementsByTagName('bs-modal-backdrop');
    const dragContainer = document.getElementById('dragContainer');
    if (modal.config.backdrop && dragContainer) {
      dragContainer.append(bsBackdrops.item(0));
    }

    /**
     * Temp hack: Avoid multiple backdrops. Happens when a modal opens another modal.
     * The gk-modal has been made by customizing the original ngx-bootstrap package for Bootstrap v3.
     * Project now runs Bootstrap v5 and updated ngx-bootstrap, and gk-modal now has quirks that should be cleaned up.
     */
    if (bsBackdrops.length > 1) {
      for (let index = bsBackdrops.length - 1; index >= 1; index--) {
        bsBackdrops[index].remove();
      }
    }
  }
}
